import { useEffect, useState, useContext } from "react";
import {
  CAMPAIGNS,
  CREATE_CAMPAIGN,
  UPDATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  GET_DOWNLOAD_LINK,
  GET_UPLOAD_LINK,
  CIRCUITS,
} from "../graphQueries";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Table,
  Loader,
  Button,
  Header,
  Icon,
  Popup,
  Segment,
} from "semantic-ui-react";
import PageModal from "../PageModal/PageModal";
import AddEditCampaign from "./AddEditCampaign";
import Alert from "../Alert/Alert";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import CopyToClipboard from "react-copy-to-clipboard";
import moment from "moment";
import { AuthContext } from "../AuthContext";

const Campaigns = () => {
  const { loginContext } = useContext(AuthContext);
  const [campaignsList, setCampaignsList] = useState([]);
  const [initializing, setInit] = useState(true);
  const [isAddEditCampaign, setAddEditCampaign] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [mutating, setMutating] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [mode, setMode] = useState("add");
  const [backgroundImageFile, setBackgroundImageFile] = useState(null);
  const [backgroundImagePreview, setBackgroundImagePreview] = useState("");
  const [artworkOneFile, setArtworkOneFile] = useState(null);
  const [artworkOnePreview, setArtworkOnePreview] = useState("");
  const [artworkTwoFile, setArtworkTwoFile] = useState(null);
  const [artworkTwoPreview, setArtworkTwoPreview] = useState("");
  const [artworkThreeFile, setArtworkThreeFile] = useState(null);
  const [artworkThreePreview, setArtworkThreePreview] = useState("");
  const [artworkFourFile, setArtworkFourFile] = useState(null);
  const [artworkFourPreview, setArtworkFourPreview] = useState("");
  const [copiedObj, setCopiedObj] = useState({});
  const [defaultBackgroundColor, setDefaultBackgroundColor] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [circuitsList, setCircuitsList] = useState([]);
  const [sort, setSort] = useState({
    column: "createdAt",
    order: "descending",
  });
  const isAdmin = loginContext.role === "Admin";

  useEffect(() => {
    campaigns();
    circuits();
  }, []);

  useEffect(() => {
    setInit(false);
  }, [campaignsList]);

  useEffect(() => {
    console.log(selectedCampaign);
  }, [selectedCampaign]);

  const [campaigns] = useLazyQuery(CAMPAIGNS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleCampaigns_Result(result),
    onError: (error) => handleCampaigns_Error(error),
  });

  const handleCampaigns_Result = (result) => {
    console.log(result);
    if (result.campaigns) {
      setCampaignsList(result.campaigns);
    }
  };

  const handleCampaigns_Error = (error) => {
    console.log(error);
  };

  const [circuits] = useLazyQuery(CIRCUITS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleCircuits_Result(result),
    onError: (error) => handleCircuits_Error(error),
  });

  const handleCircuits_Result = (result) => {
    console.log(result);
    let array = result.circuits.map((item) =>
      Object.assign({}, item, {
        text: item.circuit,
        value: item.circuitId,
      })
    );
    array.unshift({ text: "All Circuits", value: "all" });
    setCircuitsList(array);
  };

  const handleCircuits_Error = (error) => {
    console.log(error);
  };

  const [getUploadBackgroundImageLink] = useLazyQuery(GET_UPLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => getUploadBackgroundImageLink_Result(result),
    onError: (error) => getUploadBackgroundImageLink_Error(error),
  });

  const getUploadBackgroundImageLink_Result = (result) => {
    console.log(result);
    if (result.getUploadLink.link) {
      handleUpload(result.getUploadLink.link, backgroundImageFile);
    }
  };

  const getUploadBackgroundImageLink_Error = (error) => {
    console.log(error);
  };

  const [getUploadArtworkOneLink] = useLazyQuery(GET_UPLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => getUploadArtworkOneLink_Result(result),
    onError: (error) => getUploadArtworkOneLink_Error(error),
  });

  const getUploadArtworkOneLink_Result = (result) => {
    console.log(result);
    if (result.getUploadLink.link) {
      handleUpload(result.getUploadLink.link, artworkOneFile);
    }
  };

  const getUploadArtworkOneLink_Error = (error) => {
    console.log(error);
  };

  const [getUploadArtworkTwoLink] = useLazyQuery(GET_UPLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => getUploadArtworkTwoLink_Result(result),
    onError: (error) => getUploadArtworkTwoLink_Error(error),
  });

  const getUploadArtworkTwoLink_Result = (result) => {
    console.log(result);
    if (result.getUploadLink.link) {
      handleUpload(result.getUploadLink.link, artworkTwoFile);
    }
  };

  const getUploadArtworkTwoLink_Error = (error) => {
    console.log(error);
  };

  const [getUploadArtworkThreeLink] = useLazyQuery(GET_UPLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => getUploadArtworkThreeLink_Result(result),
    onError: (error) => getUploadArtworkThreeLink_Error(error),
  });

  const getUploadArtworkThreeLink_Result = (result) => {
    console.log(result);
    if (result.getUploadLink.link) {
      handleUpload(result.getUploadLink.link, artworkThreeFile);
    }
  };

  const getUploadArtworkThreeLink_Error = (error) => {
    console.log(error);
  };

  const [getUploadArtworkFourLink] = useLazyQuery(GET_UPLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => getUploadArtworkFourLink_Result(result),
    onError: (error) => getUploadArtworkFourLink_Error(error),
  });

  const getUploadArtworkFourLink_Result = (result) => {
    console.log(result);
    if (result.getUploadLink.link) {
      handleUpload(result.getUploadLink.link, artworkFourFile);
    }
  };

  const getUploadArtworkFourLink_Error = (error) => {
    console.log(error);
  };

  const handleUpload = async (link, file) => {
    const response = await axios({
      method: "PUT",
      url: link,
      headers: {
        "Content-Type": file.type,
      },
      data: file,
    });
    console.log("response", response);
  };

  const [getDownloadBackgroundImageLink] = useLazyQuery(GET_DOWNLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => getDownloadBackgroundImageLink_Result(result),
    onError: (error) => getDownloadBackgroundImageLink_Error(error),
  });

  const getDownloadBackgroundImageLink_Result = (result) => {
    if (result && result.getDownloadLink.link) {
      setBackgroundImagePreview(result.getDownloadLink.link);
    }
  };

  const getDownloadBackgroundImageLink_Error = (error) => {
    console.log(error);
  };

  const [getDownloadArtworkOneLink] = useLazyQuery(GET_DOWNLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => getDownloadArtworkOneLink_Result(result),
    onError: (error) => getDownloadArtworkOneLink_Error(error),
  });

  const getDownloadArtworkOneLink_Result = (result) => {
    if (result && result.getDownloadLink.link) {
      setArtworkOnePreview(result.getDownloadLink.link);
    }
  };

  const getDownloadArtworkOneLink_Error = (error) => {
    console.log(error);
  };

  const [getDownloadArtworkTwoLink] = useLazyQuery(GET_DOWNLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => getDownloadArtworkTwoLink_Result(result),
    onError: (error) => getDownloadArtworkTwoLink_Error(error),
  });

  const getDownloadArtworkTwoLink_Result = (result) => {
    if (result && result.getDownloadLink.link) {
      setArtworkTwoPreview(result.getDownloadLink.link);
    }
  };

  const getDownloadArtworkTwoLink_Error = (error) => {
    console.log(error);
  };

  const [getDownloadArtworkThreeLink] = useLazyQuery(GET_DOWNLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => getDownloadArtworkThreeLink_Result(result),
    onError: (error) => getDownloadArtworkThreeLink_Error(error),
  });

  const getDownloadArtworkThreeLink_Result = (result) => {
    if (result && result.getDownloadLink.link) {
      setArtworkThreePreview(result.getDownloadLink.link);
    }
  };

  const getDownloadArtworkThreeLink_Error = (error) => {
    console.log(error);
  };

  const [getDownloadArtworkFourLink] = useLazyQuery(GET_DOWNLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => getDownloadArtworkFourLink_Result(result),
    onError: (error) => getDownloadArtworkFourLink_Error(error),
  });

  const getDownloadArtworkFourLink_Result = (result) => {
    if (result && result.getDownloadLink.link) {
      setArtworkFourPreview(result.getDownloadLink.link);
    }
  };

  const getDownloadArtworkFourLink_Error = (error) => {
    console.log(error);
  };

  const handleChangeField = (e, data) => {
    const key = data.id;
    let value = e.target.value;
    if (key === "name") {
      value = e.target.value
        .replace(/\W+/g, "-")
        .replace(/-$/, "")
        .toLowerCase();
    }
    if (key === "backgroundSize") {
      value = data.value;
    }
    if (key === "circuitId") {
      value = data.value;
    }
    let obj = { ...selectedCampaign };
    Object.assign(obj, { [key]: value });
    setSelectedCampaign(obj);
  };

  const handleAddCampaign = () => {
    console.log(selectedCampaign);
    setMutating(true);
    if (selectedCampaign.name) {
      createCampaign({
        variables: selectedCampaign,
      });
    } else {
      setMutating(false);
      setErrorMessage("Please fill out all required fields.");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const getFileExtension = (filename) => {
    const parts = filename.split(".");
    return parts.length > 1 ? parts.pop() : "";
  };

  const [createCampaign] = useMutation(CREATE_CAMPAIGN, {
    onCompleted: (result) => handleCreateCampaign_Result(result),
    onError: (error) => handleCreateCampaign_Error(error),
  });

  const handleCreateCampaign_Result = (result) => {
    console.log(result);
    if (result.createCampaign.status === "succeeded") {
      console.log("inside result");
      setSuccessMessage("Campaign created successfully.");
      campaigns();
      resetState();
      // upload images now that campaign has success
      if (backgroundImageFile !== null) {
        getUploadBackgroundImageLink({
          variables: {
            file: `campaigns/${
              result.createCampaign.campaignId
            }/backgroundImage.${getFileExtension(backgroundImageFile.name)}`,
          },
        });
      }
      if (artworkOneFile !== null) {
        getUploadArtworkOneLink({
          variables: {
            file: `campaigns/${
              result.createCampaign.campaignId
            }/artworkOne.${getFileExtension(artworkOneFile.name)}`,
          },
        });
      }
      if (artworkTwoFile !== null) {
        getUploadArtworkTwoLink({
          variables: {
            file: `campaigns/${
              result.createCampaign.campaignId
            }/artworkTwo.${getFileExtension(artworkTwoFile.name)}`,
          },
        });
      }
      if (artworkThreeFile !== null) {
        getUploadArtworkThreeLink({
          variables: {
            file: `campaigns/${
              result.createCampaign.campaignId
            }/artworkThree.${getFileExtension(artworkThreeFile.name)}`,
          },
        });
      }
      if (artworkFourFile !== null) {
        getUploadArtworkFourLink({
          variables: {
            file: `campaigns/${
              result.createCampaign.campaignId
            }/artworkFour.${getFileExtension(artworkFourFile.name)}`,
          },
        });
      }
    } else {
      setErrorMessage("Error creating campaign.");
      // resetState();
      clearError();
    }
  };

  const handleCreateCampaign_Error = (error) => {
    console.log(error);
    setErrorMessage(error.message);
    // resetState();
    clearError();
  };

  const [deleteCampaign] = useMutation(DELETE_CAMPAIGN, {
    onCompleted: (result) => handleDeleteCampaign_Result(result),
    onError: (error) => handleDeleteCampaign_Error(error),
  });

  const handleDeleteCampaign_Result = (result) => {
    console.log(result);
    if (result.deleteCampaign.status === "succeeded") {
      setSuccessMessage("Campaign deleted successfully!");
      campaigns();
      resetState();
    }
  };

  const handleDeleteCampaign_Error = (error) => {
    console.log(error);
  };

  const handleDeleteCampaign = () => {
    if (!selectedCampaign.campaignId) return;
    setConfirmDelete(false);
    deleteCampaign({ variables: { campaignId: selectedCampaign.campaignId } });
  };

  const resetState = () => {
    setTimeout(() => {
      setMutating(false);
      setAddEditCampaign(false);
      setErrorMessage("");
      setSuccessMessage("");
      setSelectedCampaign("");
      setBackgroundImageFile(null);
      setArtworkOneFile(null);
      setArtworkTwoFile(null);
      setArtworkThreeFile(null);
      setArtworkFourFile(null);
      setBackgroundImagePreview("");
      setArtworkOnePreview("");
      setArtworkTwoPreview("");
      setArtworkThreePreview("");
      setArtworkFourPreview("");
    }, 3000);
  };

  const clearError = () => {
    // after errors, call this function instead of resetState to only reset the necessary pieces to fix error.
    setTimeout(() => {
      setMutating(false);
      setErrorMessage("");
      setSuccessMessage("");
    }, 4000);
  };

  const handleSetAddCampaign = () => {
    const circuitId = loginContext.circuitId || "all";
    setSelectedCampaign({
      backgroundSize: "Contain",
      textColor: "#000000",
      circuitId,
    });
    setAddEditCampaign(true);
    setMode("add");
    setBackgroundImageFile(null);
    setBackgroundImagePreview("");
    setArtworkOneFile(null);
    setArtworkOnePreview("");
    setArtworkTwoFile(null);
    setArtworkTwoPreview("");
    setArtworkThreeFile(null);
    setArtworkThreePreview("");
    setArtworkFourFile(null);
    setArtworkFourPreview("");
  };

  const handleSetEditCampaign = () => {
    setAddEditCampaign(true);
    setMode("edit");
    // fetch images for preview
    if (selectedCampaign.backgroundImagePath) {
      getDownloadBackgroundImageLink({
        variables: {
          file: `campaigns/${selectedCampaign.campaignId}/${selectedCampaign.backgroundImagePath}`,
        },
      });
    }
    if (selectedCampaign.artworkOnePath) {
      getDownloadArtworkOneLink({
        variables: {
          file: `campaigns/${selectedCampaign.campaignId}/${selectedCampaign.artworkOnePath}`,
        },
      });
    }
    if (selectedCampaign.artworkTwoPath) {
      getDownloadArtworkTwoLink({
        variables: {
          file: `campaigns/${selectedCampaign.campaignId}/${selectedCampaign.artworkTwoPath}`,
        },
      });
    }
    if (selectedCampaign.artworkThreePath) {
      getDownloadArtworkThreeLink({
        variables: {
          file: `campaigns/${selectedCampaign.campaignId}/${selectedCampaign.artworkThreePath}`,
        },
      });
    }
    if (selectedCampaign.artworkFourPath) {
      getDownloadArtworkFourLink({
        variables: {
          file: `campaigns/${selectedCampaign.campaignId}/${selectedCampaign.artworkFourPath}`,
        },
      });
    }
  };

  const handleUpdateCampaign = () => {
    setMutating(true);
    console.log(selectedCampaign);
    updateCampaign({ variables: selectedCampaign });
  };

  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN, {
    onCompleted: (result) => handleUpdateCampaign_Result(result),
    onError: (error) => handleUpdateCampaign_Error(error),
  });

  const handleUpdateCampaign_Result = (result) => {
    console.log(result);
    if (result.updateCampaign.status === "succeeded") {
      setSuccessMessage("Campaign updated successfully!");
      campaigns();
      resetState();
      // upload any new images
      if (backgroundImageFile !== null) {
        getUploadBackgroundImageLink({
          variables: {
            file: `campaigns/${result.updateCampaign.campaignId}/${backgroundImageFile.name}`,
          },
        });
      }
      if (artworkOneFile !== null) {
        getUploadArtworkOneLink({
          variables: {
            file: `campaigns/${
              result.updateCampaign.campaignId
            }/artworkOne.${getFileExtension(artworkOneFile.name)}`,
          },
        });
      }
      if (artworkTwoFile !== null) {
        getUploadArtworkTwoLink({
          variables: {
            file: `campaigns/${
              result.updateCampaign.campaignId
            }/artworkTwo.${getFileExtension(artworkTwoFile.name)}`,
          },
        });
      }
      if (artworkThreeFile !== null) {
        getUploadArtworkThreeLink({
          variables: {
            file: `campaigns/${
              result.updateCampaign.campaignId
            }/artworkThree.${getFileExtension(artworkThreeFile.name)}`,
          },
        });
      }
      if (artworkFourFile !== null) {
        getUploadArtworkFourLink({
          variables: {
            file: `campaigns/${
              result.updateCampaign.campaignId
            }/artworkFour.${getFileExtension(artworkFourFile.name)}`,
          },
        });
      }
    } else {
      setErrorMessage("Error updating campaign.");
      resetState();
    }
  };

  const handleUpdateCampaign_Error = (error) => {
    console.log(error);
    setErrorMessage(error.message);
    resetState();
  };

  const getEditDisabled = () => {
    if (selectedCampaign.campaignId) return false;
    else return true;
  };

  const handleChangeArtworkOne = async (file) => {
    const fileExt = file.type.split("/")[1];
    let image;
    image = await resizeArtworkOne(file, fileExt);
    console.log(image);
    setArtworkOneFile(image);
    const obj = { ...selectedCampaign };
    Object.assign(obj, {
      artworkOnePath: `artworkOne.${getFileExtension(image.name)}`,
    });
    setSelectedCampaign(obj);
  };

  const resizeArtworkOne = (file, ext) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1920,
        ext,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        1080,
        1920
      );
    });

  const handleChangeArtworkTwo = async (file) => {
    const fileExt = file.type.split("/")[1];
    let image;
    image = await resizeArtworkTwo(file, fileExt);
    console.log(image);
    setArtworkTwoFile(image);
    const obj = { ...selectedCampaign };
    Object.assign(obj, {
      artworkTwoPath: `artworkTwo.${getFileExtension(image.name)}`,
    });
    setSelectedCampaign(obj);
  };

  const resizeArtworkTwo = (file, ext) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        ext,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        1080,
        1080
      );
    });

  const handleChangeArtworkThree = async (file) => {
    const fileExt = file.type.split("/")[1];
    let image;
    image = await resizeArtworkThree(file, fileExt);
    console.log(image);
    setArtworkThreeFile(image);
    const obj = { ...selectedCampaign };
    Object.assign(obj, {
      artworkThreePath: `artworkThree.${getFileExtension(image.name)}`,
    });
    setSelectedCampaign(obj);
  };

  const resizeArtworkThree = (file, ext) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1920,
        1080,
        ext,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        1920,
        1080
      );
    });

  const handleChangeArtworkFour = async (file) => {
    const fileExt = file.type.split("/")[1];
    let image;
    image = await resizeArtworkFour(file, fileExt);
    console.log(image);
    setArtworkFourFile(image);
    const obj = { ...selectedCampaign };
    Object.assign(obj, {
      artworkFourPath: `artworkFour.${getFileExtension(image.name)}`,
    });
    setSelectedCampaign(obj);
  };

  const resizeArtworkFour = (file, ext) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        2550,
        2700,
        ext,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        2550,
        2700
      );
    });

  const handleChangeBackgroundImage = async (file) => {
    const fileExt = file.type.split("/")[1];
    let image;
    image = await resizeImageBackground(file, fileExt);
    console.log(image);
    setBackgroundImageFile(image);
    const obj = { ...selectedCampaign };
    Object.assign(obj, {
      backgroundImagePath: `backgroundImage.${getFileExtension(image.name)}`,
    });
    setSelectedCampaign(obj);
  };

  const resizeImageBackground = (file, ext) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1920,
        1080,
        ext,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        1280,
        720
      );
    });

  const handleChangeBackgroundColor = (color) => {
    console.log("color", color);
    const obj = { ...selectedCampaign };
    Object.assign(obj, { backgroundColor: color.hex });
    if (color.hsv.s < 0.5) {
      Object.assign(obj, { textColor: "#000000" });
    } else {
      Object.assign(obj, { textColor: "#FFFFFF" });
    }
    setSelectedCampaign(obj);
  };

  const getLink = (item) => {
    return window.location.href + "invitation/?name=" + item.name;
  };

  const handleCopied = (obj) => {
    console.log("copied!", obj);
    setCopiedObj(obj);
    setTimeout(() => {
      setCopiedObj({});
    }, 1500);
  };

  const clearImages = () => {
    console.log("inside clearImages");
    setBackgroundImageFile(null);
    setBackgroundImagePreview("");
    setArtworkOneFile(null);
    setArtworkOnePreview("");
    setArtworkTwoFile(null);
    setArtworkTwoPreview("");
    setArtworkThreeFile(null);
    setArtworkThreePreview("");
    setArtworkFourFile(null);
    setArtworkFourPreview("");
  };

  const handleGoLink = (link) => {
    console.log(link);
    window.open(link, "_blank");
  };

  const handleSort = (column) => {
    const newOrder = sort.order === "ascending" ? "descending" : "ascending";
    let sortList = [...campaignsList];
    if (newOrder === "ascending") {
      sortList.sort((a, b) => (a[column] || "").localeCompare(b[column] || ""));
    }
    if (newOrder === "descending") {
      sortList.sort((a, b) => (b[column] || "").localeCompare(a[column] || ""));
    }
    setCampaignsList(sortList);
    setSort({ column, order: newOrder });
  };

  const handleSortByTime = (column) => {
    const newOrder = sort.order === "ascending" ? "descending" : "ascending";
    let sortList = [...campaignsList];
    if (newOrder === "ascending") {
      sortList.sort((a, b) => new Date(a[column]) - new Date(b[column]));
    }
    if (newOrder === "descending") {
      sortList.sort((a, b) => new Date(b[column]) - new Date(a[column]));
    }
    setCampaignsList(sortList);
    setSort({ column, order: newOrder });
  };

  const CampaignsList = () => {
    return campaignsList.map((item, index) => {
      return (
        <Table.Row
          key={index}
          active={selectedCampaign.campaignId === item.campaignId}
          onClick={() => setSelectedCampaign(item)}
        >
          <Table.Cell>
            {moment(new Date(item.createdAt)).format("LLL")}
          </Table.Cell>
          {isAdmin && <Table.Cell>{item.userEmail}</Table.Cell>}
          <Table.Cell>{item.name}</Table.Cell>
          <Table.Cell>{item.downloads || "0"}</Table.Cell>
          <Table.Cell style={{ display: "flex", alignItems: "center" }}>
            <Popup
              open={item.campaignId === copiedObj.campaignId}
              content="Link Copied!"
              trigger={
                <CopyToClipboard
                  text={getLink(item)}
                  onCopy={() => handleCopied(item)}
                >
                  <Icon
                    title="Copy Link"
                    size="large"
                    className="custom-button"
                    name="copy"
                    color="blue"
                  />
                </CopyToClipboard>
              }
            />
            <Icon
              title="Open Link"
              style={{ marginLeft: "10px" }}
              color="blue"
              name="external square alternate"
              size="large"
              className="custom-button"
              onClick={() => handleGoLink(getLink(item))}
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "calc(100% - 50px)",
        position: "absolute",
        backgroundColor: "#BFBFBF",
      }}
    >
      <Alert error message={errorMessage} open={errorMessage} />
      <Alert success message={successMessage} open={successMessage} />
      <div
        style={{
          maxWidth: "1000px",
          width: "100%",
          backgroundColor: "#F1F1F1",
          borderRadius: "10px",
          padding: "20px",
          zIndex: "1",
          marginTop: "-50px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {initializing && <Loader content="Loading..." active inline />}
        {!initializing && (
          <div style={{ width: "100%" }}>
            <Header content="Campaigns" style={{ textAlign: "center" }} />
            {campaignsList.length ? (
              <Table selectable fixed celled sortable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      sorted={sort.column === "createdAt" ? sort.order : null}
                      onClick={() => handleSortByTime("createdAt")}
                    >
                      Created
                    </Table.HeaderCell>
                    {isAdmin && (
                      <Table.HeaderCell
                        sorted={sort.column === "userEmail" ? sort.order : null}
                        onClick={() => handleSort("userEmail")}
                      >
                        User
                      </Table.HeaderCell>
                    )}
                    <Table.HeaderCell
                      sorted={sort.column === "name" ? sort.order : null}
                      onClick={() => handleSort("name")}
                    >
                      Name
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={sort.column === "downloads" ? sort.order : null}
                      onClick={() => handleSort("downloads")}
                    >
                      Downloads
                    </Table.HeaderCell>
                    <Table.HeaderCell>Link</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <CampaignsList />
                </Table.Body>
              </Table>
            ) : (
              <Segment placeholder>
                <Header icon>
                  <Header.Subheader>No Campaigns Found.</Header.Subheader>
                </Header>
              </Segment>
            )}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Popup
                open={confirmDelete}
                trigger={
                  <Button
                    disabled={getEditDisabled()}
                    size="large"
                    icon="ban"
                    content="Delete"
                    negative
                    onClick={() => setConfirmDelete(true)}
                  />
                }
                content={
                  <div>
                    <p>
                      Really delete? This cannot be undone. This will also clear
                      any associated download records.
                    </p>
                    <Button
                      content="Cancel"
                      onClick={() => setConfirmDelete(false)}
                    />
                    <Button
                      content="Delete"
                      negative
                      onClick={handleDeleteCampaign}
                    />
                  </div>
                }
              />
              <Button
                disabled={getEditDisabled()}
                size="large"
                icon="edit"
                content="Edit"
                primary
                onClick={handleSetEditCampaign}
              />
              <Button
                size="large"
                icon="plus"
                content="Add"
                positive
                onClick={handleSetAddCampaign}
              />
            </div>
          </div>
        )}
        {!initializing && (
          <PageModal
            size="large"
            heading={mode === "add" ? "Add Campaign" : "Edit Campaign"}
            open={isAddEditCampaign}
            setOpen={setAddEditCampaign}
            clearImages={clearImages}
            actions={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  size="large"
                  content="Close"
                  onClick={() => setAddEditCampaign(false)}
                />
                <Button
                  loading={mutating}
                  disabled={mutating}
                  size="large"
                  content={mode === "add" ? "Add" : "Update"}
                  positive
                  onClick={
                    mode === "add" ? handleAddCampaign : handleUpdateCampaign
                  }
                />
              </div>
            }
          >
            <AddEditCampaign
              selectedCampaign={selectedCampaign}
              onChangeField={handleChangeField}
              backgroundImageFile={backgroundImageFile}
              backgroundImagePreview={backgroundImagePreview}
              onChangeBackgroundImage={handleChangeBackgroundImage}
              onChangeBackgroundColor={handleChangeBackgroundColor}
              artworkOneFile={artworkOneFile}
              artworkOnePreview={artworkOnePreview}
              onChangeArtworkOne={handleChangeArtworkOne}
              artworkTwoFile={artworkTwoFile}
              artworkTwoPreview={artworkTwoPreview}
              onChangeArtworkTwo={handleChangeArtworkTwo}
              artworkThreeFile={artworkThreeFile}
              artworkThreePreview={artworkThreePreview}
              onChangeArtworkThree={handleChangeArtworkThree}
              artworkFourFile={artworkFourFile}
              artworkFourPreview={artworkFourPreview}
              onChangeArtworkFour={handleChangeArtworkFour}
              setSelectedCampaign={setSelectedCampaign}
              mode={mode}
              circuitsList={circuitsList}
              isAdmin={isAdmin}
            />
          </PageModal>
        )}
      </div>
    </div>
  );
};
export default Campaigns;
