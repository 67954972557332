import {
  Form,
  Image,
  Popup,
  Icon,
  Header,
  Input,
  Divider,
  Dropdown,
  Button,
} from "semantic-ui-react";
import { FileUploader } from "react-drag-drop-files";
import { SketchPicker, BlockPicker, TwitterPicker } from "react-color";
import { useEffect, useState } from "react";
import { extractColors } from "extract-colors";
import "./style.css";

const AddEditCampaign = (props) => {
  const [colorPalette, setColorPalette] = useState([]);
  const {
    selectedCampaign,
    onChangeField,
    onChangeBackgroundImage,
    backgroundImageFile,
    backgroundImagePreview,
    onChangeBackgroundColor,
    artworkOneFile,
    artworkOnePreview,
    onChangeArtworkOne,
    artworkTwoFile,
    artworkTwoPreview,
    onChangeArtworkTwo,
    artworkThreeFile,
    artworkThreePreview,
    onChangeArtworkThree,
    artworkFourFile,
    artworkFourPreview,
    onChangeArtworkFour,
    setSelectedCampaign,
    mode,
    circuitsList,
    isAdmin,
  } = props;
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];
  const fontOptions = [
    { text: "Default", value: "inherit" },
    { text: "Cursive", value: "cursive" },
    { text: "Monospace", value: "monospace" },
    { text: "Fantasy", value: "fantasy" },
    { text: "System", value: "system-ui" },
    { text: "None", value: "none" },
  ];
  const colorOptions = [
    { text: "Dark", value: "#000000" },
    { text: "Light", value: "#FFFFFF" },
  ];

  const getColors = (img) => {
    console.log("img", img);
    extractColors(img, { crossOrigin: "anonymous" })
      .then((colors) => {
        console.log("colors", colors);
        if (mode === "add") {
          let obj = { ...selectedCampaign };
          Object.assign(obj, {
            backgroundColor: colors[0].hex,
            buttonColor: colors[1].hex,
          });

          if (colors[0].lightness < 0.5) {
            Object.assign(obj, { textColor: "#000000" });
          } else {
            Object.assign(obj, { textColor: "#FFFFFF" });
          }
          setSelectedCampaign(obj);
        }
        let items = [];
        for (const c of colors) {
          if (items.indexOf(c.hex) === -1) {
            items.push(c.hex);
          }
        }

        setColorPalette(items);
      })
      .catch(console.error);
  };

  useEffect(() => {
    getColors(
      backgroundImageFile
        ? URL.createObjectURL(backgroundImageFile)
        : backgroundImagePreview
        ? backgroundImagePreview
        : "/images/placeholder.png"
    );
  }, [backgroundImageFile, backgroundImagePreview]);

  const handleChangeColor = (e, data) => {
    let obj = { ...selectedCampaign };
    Object.assign(obj, { textColor: data.value });
    setSelectedCampaign(obj);
  };

  const handleChangeButtonColor = (color) => {
    console.log("color", color);
    const obj = { ...selectedCampaign };
    Object.assign(obj, { buttonColor: color.hex });
    setSelectedCampaign(obj);
  };

  return (
    <Form size="large">
      <Form.Field>
        <label>
          {" "}
          <span style={{ fontWeight: 600 }}>
            Name{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Enter all lower case with no special characters or spaces. This will be part of URL that is sent to the customer."
            />
          </span>
        </label>
        <Input
          id="name"
          value={selectedCampaign.name || ""}
          placeholder="e.g. mymovietitle"
          onChange={onChangeField}
        />
      </Form.Field>
      {isAdmin && (
        <Form.Field>
          <label>
            {" "}
            <span style={{ fontWeight: 600 }}>
              Circuit{" "}
              <Popup
                trigger={<Icon name="info circle" />}
                content="Select a specific Circuit to limit the theater selection, or choose All Circuits."
              />
            </span>
          </label>
          <Dropdown
            id="circuitId"
            selection
            search
            options={circuitsList}
            value={selectedCampaign.circuitId}
            onChange={onChangeField}
          />
        </Form.Field>
      )}
      {/* <Divider horizontal section style={{ marginTop: "4rem" }}>
        <Header as="h3">
          <Icon name="paint brush" />
          Landing Page Customization
        </Header>
      </Divider> */}

      {/* <Form.Field>
        <label>
          {" "}
          <span style={{ fontWeight: 600 }}>
            Background Artwork{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Background image for the generator landing page."
            />
          </span>
        </label>
        <div>
          <FileUploader
            handleChange={onChangeBackgroundImage}
            name="file"
            types={fileTypes}
            hoverTitle=" "
            children={
              <div
                style={{
                  cursor: "pointer",
                  borderStyle: "dashed",
                  flex: 1,
                  height: "150px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "10px",
                  borderColor: "#888888",
                }}
              >
                <Image
                  crossOrigin="anonymous"
                  style={{
                    maxWidth: "250px",
                    maxHeight: "130px",
                    margin: "10px",
                  }}
                  src={
                    backgroundImageFile
                      ? URL.createObjectURL(backgroundImageFile)
                      : backgroundImagePreview
                      ? backgroundImagePreview
                      : "/images/placeholder.png"
                  }
                />
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Header>
                    <Icon name="upload" />
                    <Header.Content>
                      Drop a file here, or click to browse
                      <Header.Subheader>
                        Recommended: 1920 x 1080 JPEG
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
              </div>
            }
          />
        </div>
      </Form.Field> */}

      {/* <Form.Group>
        <Form.Field width={8}>
          <label>
            {" "}
            <span style={{ fontWeight: 600 }}>
              Background Color{" "}
              <Popup
                trigger={<Icon name="info circle" />}
                content="Background color for generator landing page and invitations."
              />
            </span>
          </label>
          <div
            style={{ border: "1px solid rgba(0,0,0,0.2)", borderRadius: "4px" }}
          >
            <div
              style={{
                backgroundColor: selectedCampaign.backgroundColor || "",
                borderTopRightRadius: "4px",
                borderTopLeftRadius: "4px",
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 4px",
              }}
            >
              <Header
                style={{
                  color: selectedCampaign.textColor,
                }}
              >
                Example Text
              </Header>
            </div>
            <TwitterPicker
              triangle="hide"
              disableAlpha
              color={selectedCampaign.backgroundColor || ""}
              colors={colorPalette}
              onChangeComplete={onChangeBackgroundColor}
            />
          </div>
        </Form.Field>
        <Form.Field width={8}>
          <label>
            {" "}
            <span style={{ fontWeight: 600 }}>
              Button Color{" "}
              <Popup
                trigger={<Icon name="info circle" />}
                content="Background color for generator button on the form. Button text is always white, so ensure the background color is dark/bold."
              />
            </span>
          </label>
          <div
            style={{
              border: "1px solid rgba(0,0,0,0.2)",
              borderRadius: "4px",
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: selectedCampaign.buttonColor || "",
                borderTopRightRadius: "4px",
                borderTopLeftRadius: "4px",
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 4px",
              }}
            >
              <Header
                style={{
                  color: "#FFFFFF",
                }}
              >
                Button Example
              </Header>
            </div>
            <TwitterPicker
              triangle="hide"
              disableAlpha
              color={selectedCampaign.buttonColor || ""}
              colors={colorPalette}
              onChangeComplete={handleChangeButtonColor}
            />
          </div>
        </Form.Field>
      </Form.Group> */}

      {/* <Form.Group>
        <Form.Field width={8}>
          <label>
            {" "}
            <span style={{ fontWeight: 600 }}>
              Background Size{" "}
              <Popup
                trigger={<Icon name="info circle" />}
                content="Sets the fill behavior of the background image. Fit will scale the image down to keep the entire image visible. Fill will zoom the image to fill the browser window."
              />
            </span>
          </label>
          <Dropdown
            id="backgroundSize"
            selection
            value={selectedCampaign.backgroundSize}
            options={[
              {
                text: "Fit",
                value: "Contain",
              },
              {
                text: "Fill",
                value: "Cover",
              },
            ]}
            onChange={onChangeField}
          />
        </Form.Field>
        <Form.Field width={8}>
          <label>
            {" "}
            <span style={{ fontWeight: 600 }}>
              Text Color{" "}
              <Popup
                trigger={<Icon name="info circle" />}
                content="Text color should be light or dark depending on the background color. Choose the option with the best contrast (e.g. Light text for dark/bold background colors, Dark text for light background colors)."
              />
            </span>
          </label>
          <Dropdown
            selection
            value={selectedCampaign.textColor}
            onChange={handleChangeColor}
            options={colorOptions}
          />
        </Form.Field>
      </Form.Group> */}

      {/* <Divider horizontal section style={{ marginTop: "4rem" }}>
        <Header as="h3">
          <Icon name="paint brush" />
          Invitation Customization
        </Header>
      </Divider> */}

      <Form.Field>
        <label>
          {" "}
          <span style={{ fontWeight: 600 }}>
            Artwork #1{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="A 1080 x 1920 image."
            />
          </span>
        </label>
        <div>
          <FileUploader
            handleChange={onChangeArtworkOne}
            name="file"
            types={fileTypes}
            hoverTitle=" "
            children={
              <div
                className="dashed-border"
                style={{
                  cursor: "pointer",
                  borderStyle: "dashed",
                  border: "3px dashed #888888",
                  flex: 1,
                  height: "270px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "10px",
                  borderColor: "#888888",
                }}
              >
                <Image
                  style={{
                    maxWidth: "250px",
                    maxHeight: "250px",
                    margin: "10px",
                  }}
                  src={
                    artworkOneFile
                      ? URL.createObjectURL(artworkOneFile)
                      : artworkOnePreview
                      ? artworkOnePreview
                      : "/images/placeholder.png"
                  }
                />
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <Header>
                      <Icon name="upload" />
                      <Header.Content>
                        Drag and drop a file here
                        <Header.Subheader>
                          Recommended: 1080 x 1920 JPEG
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Button size="large" content="Browse" />
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </Form.Field>

      <Divider section />

      <Form.Field>
        <label>
          {" "}
          <span style={{ fontWeight: 600 }}>
            Artwork #2{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="A 1080 x 1080 image."
            />
          </span>
        </label>
        <div>
          <FileUploader
            handleChange={onChangeArtworkTwo}
            name="file"
            types={fileTypes}
            hoverTitle=" "
            children={
              <div
                style={{
                  cursor: "pointer",
                  borderStyle: "dashed",
                  border: "3px dashed #888888",
                  flex: 1,
                  height: "270px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "10px",
                  borderColor: "#888888",
                }}
              >
                <Image
                  style={{
                    maxWidth: "250px",
                    maxHeight: "250px",
                    margin: "10px",
                  }}
                  src={
                    artworkTwoFile
                      ? URL.createObjectURL(artworkTwoFile)
                      : artworkTwoPreview
                      ? artworkTwoPreview
                      : "/images/placeholder.png"
                  }
                />
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <Header>
                      <Icon name="upload" />
                      <Header.Content>
                        Drag and drop a file here
                        <Header.Subheader>
                          Recommended: 1080 x 1080 JPEG
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Button size="large" content="Browse" />
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </Form.Field>
      <Divider section />
      <Form.Field>
        <label>
          {" "}
          <span style={{ fontWeight: 600 }}>
            Artwork #3{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="A 1920 x 1080 image."
            />
          </span>
        </label>
        <div>
          <FileUploader
            handleChange={onChangeArtworkThree}
            name="file"
            types={fileTypes}
            hoverTitle=" "
            children={
              <div
                style={{
                  cursor: "pointer",
                  borderStyle: "dashed",
                  border: "3px dashed #888888",
                  flex: 1,
                  height: "220px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "10px",
                  borderColor: "#888888",
                }}
              >
                <Image
                  style={{
                    maxWidth: "350px",
                    maxHeight: "200px",
                    margin: "10px",
                  }}
                  src={
                    artworkThreeFile
                      ? URL.createObjectURL(artworkThreeFile)
                      : artworkThreePreview
                      ? artworkThreePreview
                      : "/images/placeholder.png"
                  }
                />
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <Header>
                      <Icon name="upload" />
                      <Header.Content>
                        Drag and drop a file here
                        <Header.Subheader>
                          Recommended: 1920 x 1080 JPEG
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Button size="large" content="Browse" />
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </Form.Field>
      <Divider section />
      <Form.Field>
        <label>
          {" "}
          <span style={{ fontWeight: 600 }}>
            Artwork #4{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="A 2550 x 2700 image."
            />
          </span>
        </label>
        <div>
          <FileUploader
            handleChange={onChangeArtworkFour}
            name="file"
            types={fileTypes}
            hoverTitle=" "
            children={
              <div
                style={{
                  cursor: "pointer",
                  borderStyle: "dashed",
                  border: "3px dashed #888888",
                  flex: 1,
                  height: "270px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "10px",
                  borderColor: "#888888",
                }}
              >
                <Image
                  style={{
                    maxWidth: "250px",
                    maxHeight: "250px",
                    margin: "10px",
                  }}
                  src={
                    artworkFourFile
                      ? URL.createObjectURL(artworkFourFile)
                      : artworkFourPreview
                      ? artworkFourPreview
                      : "/images/placeholder.png"
                  }
                />
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <Header>
                      <Icon name="upload" />
                      <Header.Content>
                        Drag and drop a file here
                        <Header.Subheader>
                          Recommended: 2550 x 2700 JPEG
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Button size="large" content="Browse" />
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </Form.Field>
    </Form>
  );
};
export default AddEditCampaign;
