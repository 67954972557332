import { Divider, Header } from "semantic-ui-react";
import OverlayTable from "./OverlayTable";
const ArtworkFourOverlay = (props) => {
  const {
    isHoverArtworkFour,
    handleIncreaseFont,
    handleDecreaseFont,
    handleChangeFontWeight,
    handleChangeLayout,
  } = props;
  return (
    <div
      style={{
        position: "absolute",
        width: "2050px",
        height: "2653px",
        backgroundColor: "rgba(0,0,0,0.8)",
        opacity: isHoverArtworkFour ? 1 : 0,
        transition: "ease-in-out 0.3s opacity",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        zIndex: 2,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "30px",
          flexDirection: "column",
        }}
      >
        <Header
          content="Header"
          inverted
          style={{ textAlign: "center", fontSize: 30 }}
        />
        <Divider inverted />
        <OverlayTable
          title1="Event Name"
          title1FontSize="eventNameArtworkFourFontSize"
          title1FontWeight="eventNameArtworkFourFontWeight"
          title1Layout="artworkFourHeaderLayout"
          title1Justify="artworkFourHeaderJustify"
          title2="Event Date"
          title2FontSize="eventDateArtworkFourFontSize"
          title2FontWeight="eventDateArtworkFourFontWeight"
          title2Layout="artworkFourHeaderLayout"
          title2Justify="artworkFourHeaderJustify"
          handleIncreaseFont={handleIncreaseFont}
          handleDecreaseFont={handleDecreaseFont}
          handleChangeFontWeight={handleChangeFontWeight}
          handleChangeLayout={handleChangeLayout}
        />
      </div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Header
          style={{ fontSize: 60, margin: "0px 10px 00px 0px" }}
          inverted
          content="Invitation"
        />
        <Header
          style={{ fontSize: 50, margin: "0px" }}
          className="circle-bullet-large"
          content="#4"
        />
      </div> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "30px",
          flexDirection: "column",
        }}
      >
        <Header
          content="Footer"
          inverted
          style={{ textAlign: "center", fontSize: 30 }}
        />
        <Divider inverted />
        <OverlayTable
          title1="Event Theater"
          title1FontSize="eventTheaterArtworkFourFontSize"
          title1FontWeight="eventTheaterArtworkFourFontWeight"
          title1Layout="artworkFourFooterLayout"
          title1Justify="artworkFourFooterJustify"
          title2="Event Location"
          title2FontSize="eventLocationArtworkFourFontSize"
          title2FontWeight="eventLocationArtworkFourFontWeight"
          title2Layout="artworkFourFooterLayout"
          title2Justify="artworkFourFooterJustify"
          title3="Event RSVP"
          title3FontSize="eventContactArtworkFourFontSize"
          title3FontWeight="eventContactArtworkFourFontWeight"
          title3Layout="artworkFourFooterLayout"
          title3Justify="artworkFourFooterJustify"
          handleIncreaseFont={handleIncreaseFont}
          handleDecreaseFont={handleDecreaseFont}
          handleChangeFontWeight={handleChangeFontWeight}
          handleChangeLayout={handleChangeLayout}
        />
      </div>
    </div>
  );
};
export default ArtworkFourOverlay;
