import { Button, Header, Table } from "semantic-ui-react";

const OverlayTable = (props) => {
  const {
    title1,
    title1FontSize,
    title1FontWeight,
    title1Layout,
    title1Justify,
    title2,
    title2FontSize,
    title2FontWeight,
    title2Layout,
    title2Justify,
    title3,
    title3FontSize,
    title3FontWeight,
    title3Layout,
    title3Justify,
    title4,
    title4FontSize,
    title4FontWeight,
    title4Layout,
    title4Justify,
    hideLayout,
  } = props;
  return (
    <Table
      inverted
      basic="very"
      celled
      collapsing
      size="large"
      style={{ fontSize: "1.5em" }}
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Text</Table.HeaderCell>
          <Table.HeaderCell>Size</Table.HeaderCell>
          <Table.HeaderCell>Bold</Table.HeaderCell>
          <Table.HeaderCell>Layout</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {title1 && (
          <Table.Row>
            <Table.Cell>
              <Header inverted>
                <Header.Content>{title1}</Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>
              <Button
                size="large"
                icon="plus"
                onClick={() => props.handleIncreaseFont(title1FontSize)}
              />
              <Button
                size="large"
                icon="minus"
                onClick={() => props.handleDecreaseFont(title1FontSize)}
              />
            </Table.Cell>
            <Table.Cell>
              <Button
                size="large"
                icon="refresh"
                onClick={() => props.handleChangeFontWeight(title1FontWeight)}
              />
            </Table.Cell>
            {!hideLayout && (
              <Table.Cell>
                <Button
                  size="large"
                  icon="refresh"
                  onClick={() =>
                    props.handleChangeLayout(title1Layout, title1Justify)
                  }
                />
              </Table.Cell>
            )}
          </Table.Row>
        )}
        {title2 && (
          <Table.Row>
            <Table.Cell>
              <Header inverted>
                <Header.Content>{title2}</Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>
              <Button
                size="large"
                icon="plus"
                onClick={() => props.handleIncreaseFont(title2FontSize)}
              />
              <Button
                size="large"
                icon="minus"
                onClick={() => props.handleDecreaseFont(title2FontSize)}
              />
            </Table.Cell>
            <Table.Cell>
              <Button
                size="large"
                icon="refresh"
                onClick={() => props.handleChangeFontWeight(title2FontWeight)}
              />
            </Table.Cell>
            {/* <Table.Cell>
              <Button
                size="large"
                icon="refresh"
                onClick={() =>
                  props.handleChangeLayout(title2Layout, title2Justify)
                }
              />
            </Table.Cell> */}
          </Table.Row>
        )}
        {title3 && (
          <Table.Row>
            <Table.Cell>
              <Header inverted>
                <Header.Content>{title3}</Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>
              <Button
                size="large"
                icon="plus"
                onClick={() => props.handleIncreaseFont(title3FontSize)}
              />
              <Button
                size="large"
                icon="minus"
                onClick={() => props.handleDecreaseFont(title3FontSize)}
              />
            </Table.Cell>
            <Table.Cell>
              <Button
                size="large"
                icon="refresh"
                onClick={() => props.handleChangeFontWeight(title3FontWeight)}
              />
            </Table.Cell>
            {/* <Table.Cell>
              <Button
                size="large"
                icon="refresh"
                onClick={() =>
                  props.handleChangeLayout(title3Layout, title3Justify)
                }
              />
            </Table.Cell> */}
          </Table.Row>
        )}
        {title4 && (
          <Table.Row>
            <Table.Cell>
              <Header inverted>
                <Header.Content>{title4}</Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>
              <Button
                size="large"
                icon="plus"
                onClick={() => props.handleIncreaseFont(title4FontSize)}
              />
              <Button
                size="large"
                icon="minus"
                onClick={() => props.handleDecreaseFont(title4FontSize)}
              />
            </Table.Cell>
            <Table.Cell>
              <Button
                size="large"
                icon="refresh"
                onClick={() => props.handleChangeFontWeight(title4FontWeight)}
              />
            </Table.Cell>
            {/* <Table.Cell>
              <Button
                size="large"
                icon="refresh"
                onClick={() =>
                  props.handleChangeLayout(title4Layout, title4Justify)
                }
              />
            </Table.Cell> */}
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};
export default OverlayTable;
