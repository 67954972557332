import { Divider, Header } from "semantic-ui-react";
import OverlayTable from "./OverlayTable";
const ArtworkThreeOverlay = (props) => {
  const {
    isHoverArtworkThree,
    handleIncreaseFont,
    handleDecreaseFont,
    handleChangeFontWeight,
    handleChangeLayout,
  } = props;
  return (
    <div
      style={{
        position: "absolute",
        width: "1420px",
        height: "799px",
        backgroundColor: "rgba(0,0,0,0.8)",
        opacity: isHoverArtworkThree ? 1 : 0,
        transition: "ease-in-out 0.3s opacity",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        zIndex: 2,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "30px",
          flexDirection: "column",
        }}
      >
        <Header
          content="Header"
          inverted
          style={{ textAlign: "center", fontSize: 30 }}
        />
        <Divider inverted />
        <OverlayTable
          title1="Event Name"
          title1FontSize="eventNameArtworkThreeFontSize"
          title1FontWeight="eventNameArtworkThreeFontWeight"
          title1Layout="artworkThreeHeaderLayout"
          title1Justify="artworkThreeHeaderJustify"
          title2="Event Date"
          title2FontSize="eventDateArtworkThreeFontSize"
          title2FontWeight="eventDateArtworkThreeFontWeight"
          title2Layout="artworkThreeHeaderLayout"
          title2Justify="artworkThreeHeaderJustify"
          handleIncreaseFont={handleIncreaseFont}
          handleDecreaseFont={handleDecreaseFont}
          handleChangeFontWeight={handleChangeFontWeight}
          handleChangeLayout={handleChangeLayout}
        />
      </div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Header
          style={{ fontSize: 60, margin: "0px 10px 00px 0px" }}
          inverted
          content="Invitation"
        />
        <Header
          style={{ fontSize: 50, margin: "0px" }}
          className="circle-bullet-large"
          content="#3"
        />
      </div> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "30px",
          flexDirection: "column",
        }}
      >
        <Header
          content="Footer"
          inverted
          style={{ textAlign: "center", fontSize: 30 }}
        />
        <Divider inverted />
        <OverlayTable
          title1="Event Theater"
          title1FontSize="eventTheaterArtworkThreeFontSize"
          title1FontWeight="eventTheaterArtworkThreeFontWeight"
          title1Layout="artworkThreeFooterLayout"
          title1Justify="artworkThreeFooterJustify"
          title2="Event Location"
          title2FontSize="eventLocationArtworkThreeFontSize"
          title2FontWeight="eventLocationArtworkThreeFontWeight"
          title2Layout="artworkThreeFooterLayout"
          title2Justify="artworkThreeFooterJustify"
          title3="Event RSVP"
          title3FontSize="eventContactArtworkThreeFontSize"
          title3FontWeight="eventContactArtworkThreeFontWeight"
          title3Layout="artworkThreeFooterLayout"
          title3Justify="artworkThreeFooterJustify"
          handleIncreaseFont={handleIncreaseFont}
          handleDecreaseFont={handleDecreaseFont}
          handleChangeFontWeight={handleChangeFontWeight}
          handleChangeLayout={handleChangeLayout}
        />
      </div>
    </div>
  );
};
export default ArtworkThreeOverlay;
