import { Divider, Header } from "semantic-ui-react";
import OverlayTable from "./OverlayTable";
const ArtworkTwoOverlay = (props) => {
  const {
    isHoverArtworkTwo,
    handleIncreaseFont,
    handleDecreaseFont,
    handleChangeFontWeight,
    handleChangeLayout,
  } = props;
  return (
    <div
      style={{
        position: "absolute",
        width: "780px",
        height: "780px",
        backgroundColor: "rgba(0,0,0,0.8)",
        opacity: isHoverArtworkTwo ? 1 : 0,
        transition: "ease-in-out 0.3s opacity",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        zIndex: 2,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "20px",
          flexDirection: "column",
        }}
      >
        <Header
          content="Header"
          inverted
          style={{ textAlign: "center", fontSize: 30 }}
        />
        <Divider inverted />
        <OverlayTable
          title1="Event Name"
          title1FontSize="eventNameArtworkTwoFontSize"
          title1FontWeight="eventNameArtworkTwoFontWeight"
          title1Layout="artworkTwoHeaderLayout"
          title1Justify="artworkTwoHeaderJustify"
          handleIncreaseFont={handleIncreaseFont}
          handleDecreaseFont={handleDecreaseFont}
          handleChangeFontWeight={handleChangeFontWeight}
          handleChangeLayout={handleChangeLayout}
          hideLayout={true}
        />
      </div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Header
          style={{ fontSize: 60, margin: "0px 10px 00px 0px" }}
          inverted
          content="Invitation"
        />
        <Header
          style={{ fontSize: 50, margin: "0px" }}
          className="circle-bullet-large"
          content="#2"
        />
      </div> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "20px",
          flexDirection: "column",
        }}
      >
        <Header
          content="Footer"
          inverted
          style={{ textAlign: "center", fontSize: 30 }}
        />
        <Divider inverted />
        <OverlayTable
          title1="Event Date"
          title1FontSize="eventDateArtworkTwoFontSize"
          title1FontWeight="eventDateArtworkTwoFontWeight"
          title1Layout="artworkTwoFooterLayout"
          title1Justify="artworkTwoFooterJustify"
          title2="Event Theater"
          title2FontSize="eventTheaterArtworkTwoFontSize"
          title2FontWeight="eventTheaterArtworkTwoFontWeight"
          title2Layout="artworkTwoFooterLayout"
          title2Justify="artworkTwoFooterJustify"
          title3="Event Location"
          title3FontSize="eventLocationArtworkTwoFontSize"
          title3FontWeight="eventLocationArtworkTwoFontWeight"
          title3Layout="artworkTwoFooterLayout"
          title3Justify="artworkTwoFooterJustify"
          title4="Event RSVP"
          title4FontSize="eventContactArtworkTwoFontSize"
          title4FontWeight="eventContactArtworkTwoFontWeight"
          title4Layout="artworkTwoFooterLayout"
          title4Justify="artworkTwoFooterJustify"
          handleIncreaseFont={handleIncreaseFont}
          handleDecreaseFont={handleDecreaseFont}
          handleChangeFontWeight={handleChangeFontWeight}
          handleChangeLayout={handleChangeLayout}
        />
      </div>
    </div>
  );
};
export default ArtworkTwoOverlay;
