import { gql } from "@apollo/client";

// queries
export const USERS = gql`
  query users {
    users {
      userId
      email
      role
      status
      createdAt
      lastLogin
      circuitId
    }
  }
`;

export const CAMPAIGNS = gql`
  query campaigns($name: String) {
    campaigns(name: $name) {
      campaignId
      name
      backgroundColor
      backgroundImagePath
      backgroundSize
      artworkOnePath
      artworkTwoPath
      artworkThreePath
      artworkFourPath
      backgroundImageLink
      artworkOneLink
      artworkTwoLink
      artworkThreeLink
      artworkFourLink
      textColor
      buttonColor
      createdAt
      invitationColor
      downloads
      userId
      userEmail
      circuitId
    }
  }
`;

export const THEATERS = gql`
  query theaters($searchTerm: String, $circuitId: String) {
    theaters(searchTerm: $searchTerm, circuitId: $circuitId) {
      theaterId
      name
      location
    }
  }
`;

export const DOWNLOADS = gql`
  query downloads {
    downloads {
      downloadId
      createdAt
      campaignId
      type
    }
  }
`;

export const GET_UPLOAD_LINK = gql`
  query getUploadLink($file: String) {
    getUploadLink(file: $file) {
      link
    }
  }
`;

export const GET_DOWNLOAD_LINK = gql`
  query getDownloadLink($file: String) {
    getDownloadLink(file: $file) {
      link
    }
  }
`;

export const CIRCUITS = gql`
  query circuits {
    circuits {
      circuit
      circuitId
    }
  }
`;

// mutations

export const CREATE_PASS = gql`
  mutation createPass($email: String!) {
    createPass(email: $email) {
      status
    }
  }
`;

export const VALIDATE_PASS = gql`
  mutation validatePass($email: String!, $passcode: String!) {
    validatePass(email: $email, passcode: $passcode) {
      status
      token
      message
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($email: String!, $role: String!, $circuitId: String) {
    createUser(
      userInfo: { email: $email, role: $role, circuitId: $circuitId }
    ) {
      status
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $userId: String!
    $email: String
    $role: String
    $status: String
    $circuitId: String
  ) {
    updateUser(
      userInfo: {
        userId: $userId
        email: $email
        role: $role
        status: $status
        circuitId: $circuitId
      }
    ) {
      status
      message
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation createCampaign(
    $name: String!
    $backgroundColor: String
    $backgroundImagePath: String
    $backgroundSize: String
    $artworkOnePath: String
    $artworkTwoPath: String
    $artworkThreePath: String
    $artworkFourPath: String
    $textColor: String
    $buttonColor: String
    $invitationColor: String
    $circuitId: String
  ) {
    createCampaign(
      campaignInfo: {
        name: $name
        backgroundColor: $backgroundColor
        backgroundImagePath: $backgroundImagePath
        backgroundSize: $backgroundSize
        artworkOnePath: $artworkOnePath
        artworkTwoPath: $artworkTwoPath
        artworkThreePath: $artworkThreePath
        artworkFourPath: $artworkFourPath
        textColor: $textColor
        buttonColor: $buttonColor
        invitationColor: $invitationColor
        circuitId: $circuitId
      }
    ) {
      status
      campaignId
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign(
    $campaignId: String!
    $name: String
    $backgroundColor: String
    $backgroundImagePath: String
    $backgroundSize: String
    $artworkOnePath: String
    $artworkTwoPath: String
    $artworkThreePath: String
    $artworkFourPath: String
    $textColor: String
    $buttonColor: String
    $invitationColor: String
    $circuitId: String
  ) {
    updateCampaign(
      campaignInfo: {
        campaignId: $campaignId
        name: $name
        backgroundColor: $backgroundColor
        backgroundImagePath: $backgroundImagePath
        backgroundSize: $backgroundSize
        artworkOnePath: $artworkOnePath
        artworkTwoPath: $artworkTwoPath
        artworkThreePath: $artworkThreePath
        artworkFourPath: $artworkFourPath
        textColor: $textColor
        buttonColor: $buttonColor
        invitationColor: $invitationColor
        circuitId: $circuitId
      }
    ) {
      status
      campaignId
    }
  }
`;

export const DELETE_CAMPAIGN = gql`
  mutation deleteCampaign($campaignId: String!) {
    deleteCampaign(campaignInfo: { campaignId: $campaignId }) {
      status
    }
  }
`;

export const CREATE_DOWNLOAD = gql`
  mutation createDownload($campaignId: String!, $type: String) {
    createDownload(downloadInfo: { campaignId: $campaignId, type: $type }) {
      status
    }
  }
`;
