import { useEffect, useState, useRef } from "react";
import { CAMPAIGNS, THEATERS } from "../graphQueries";
import { useLazyQuery } from "@apollo/client";
import {
  Form,
  Header,
  Button,
  Dropdown,
  Icon,
  Divider,
  Dimmer,
  Image,
  Checkbox,
} from "semantic-ui-react";
import Downloader from "./Downloader";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import html2canvas from "html2canvas";
import { CREATE_DOWNLOAD } from "../graphQueries";
import { useMutation } from "@apollo/client";
import FontChanger from "./FontChanger";
import { CirclePicker } from "react-color";
import { extractColors } from "extract-colors";
import { FileUploader } from "react-drag-drop-files";
import Resizer from "react-image-file-resizer";

const Builder = () => {
  const local = window.location.hostname === "localhost";
  const [campaign, setCampaign] = useState({});
  const [isCampaignReady, setReady] = useState(false);
  const [initializing, setInit] = useState(true);
  const [formFields, setFormFields] = useState({
    eventName: local ? "Peter's Big Party" : "",
    eventNameArtworkOneFontSize: 50,
    eventNameArtworkTwoFontSize: 70,
    eventNameArtworkThreeFontSize: 50,
    eventNameArtworkFourFontSize: 130,
    eventNameArtworkOneFontWeight: 800,
    eventNameArtworkTwoFontWeight: 800,
    eventNameArtworkThreeFontWeight: 800,
    eventNameArtworkFourFontWeight: 800,
    eventNameTextAlign: "center",
    eventDate: local ? new Date() : "",
    eventDateArtworkOneFontSize: 40,
    eventDateArtworkTwoFontSize: 30,
    eventDateArtworkThreeFontSize: 40,
    eventDateArtworkFourFontSize: 70,
    eventDateArtworkOneFontWeight: 400,
    eventDateArtworkTwoFontWeight: 800,
    eventDateArtworkThreeFontWeight: 400,
    eventDateArtworkFourFontWeight: 400,
    eventDateTextAlign: "center",
    eventTheater: local ? "AMC DINE-IN Framingham 16" : "",
    eventTheaterArtworkOneFontSize: 40,
    eventTheaterArtworkTwoFontSize: 25,
    eventTheaterArtworkThreeFontSize: 40,
    eventTheaterArtworkFourFontSize: 70,
    eventTheaterArtworkOneFontWeight: 800,
    eventTheaterArtworkTwoFontWeight: 800,
    eventTheaterArtworkThreeFontWeight: 800,
    eventTheaterArtworkFourFontWeight: 800,
    eventTheaterTextAlign: "center",
    eventLocation: local ? "Framingham, MA 01701" : "",
    eventLocationArtworkOneFontSize: 30,
    eventLocationArtworkTwoFontSize: 20,
    eventLocationArtworkThreeFontSize: 30,
    eventLocationArtworkFourFontSize: 50,
    eventLocationArtworkOneFontWeight: 400,
    eventLocationArtworkTwoFontWeight: 400,
    eventLocationArtworkThreeFontWeight: 400,
    eventLocationArtworkFourFontWeight: 400,
    eventLocationTextAlign: "center",
    eventRsvp: local ? "January 24, 2025" : "",
    eventRsvpArtworkOneFontSize: 20,
    eventRsvpArtworkTwoFontSize: 20,
    eventRsvpArtworkThreeFontSize: 20,
    eventRsvpArtworkFourFontSize: 20,
    eventRsvpArtworkOneFontWeight: 400,
    eventRsvpArtworkTwoFontWeight: 400,
    eventRsvpArtworkThreeFontWeight: 400,
    eventRsvpArtworkFourFontWeight: 400,
    eventRsvpTextAlign: "center",
    eventContact: local ? "peterzanetti@mac.com" : "",
    eventContactArtworkOneFontSize: 20,
    eventContactArtworkTwoFontSize: 15,
    eventContactArtworkThreeFontSize: 20,
    eventContactArtworkFourFontSize: 30,
    eventContactArtworkOneFontWeight: 400,
    eventContactArtworkTwoFontWeight: 400,
    eventContactArtworkThreeFontWeight: 400,
    eventContactArtworkFourFontWeight: 400,
    eventContactTextAlign: "center",
    eventFont: "Oswald",
  });
  const [searchResults, setResults] = useState([]);
  const [searchDisabled, setSearchDisabled] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [scaleFactor, setScaleFactor] = useState(5);
  const [scale, setScale] = useState("0.5");
  const [colorPalette, setColorPalette] = useState([]);
  const [artworkOptions, setArtworkOptions] = useState([]);
  const [activeFontEdit, setActiveFontEdit] = useState("");
  const [logoFile, setLogoFile] = useState(null);
  const [logoPreview, setLogoPreview] = useState("");
  const [isLogo, setLogo] = useState(true);
  const [logoPosition, setLogoPosition] = useState("top left");
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];
  const fontOptions = [
    {
      text: "Lato",
      value: "Lato",
      content: <span style={{ fontFamily: "Lato" }}>Lato</span>,
    },
    {
      text: "IBM Plex Mono",
      value: "IBM Plex Mono",
      content: (
        <span style={{ fontFamily: "IBM Plex Mono" }}>IBM Plex Mono</span>
      ),
    },
    {
      text: "Josefin Sans",
      value: "Josefin Sans",
      content: <span style={{ fontFamily: "Josefin Sans" }}>Josefin Sans</span>,
    },
    {
      text: "Oswald",
      value: "Oswald",
      content: <span style={{ fontFamily: "Oswald" }}>Oswald</span>,
    },
    {
      text: "Playfair Display",
      value: "Playfair Display",
      content: (
        <span style={{ fontFamily: "Playfair Display" }}>Playfair Display</span>
      ),
    },
    {
      text: "Playwrite US Modern",
      value: "Playwrite US Modern",
      content: (
        <span style={{ fontFamily: "Playwrite US Modern" }}>
          Playwrite US Modern
        </span>
      ),
    },
    {
      text: "Rouge Script",
      value: "Rouge Script",
      content: <span style={{ fontFamily: "Rouge Script" }}>Rouge Script</span>,
    },
    {
      text: "Rubik Doodle Triangles",
      value: "Rubik Doodle Triangles",
      content: (
        <span style={{ fontFamily: "Rubik Doodle Triangles" }}>
          Rubik Doodle Triangles
        </span>
      ),
    },
  ];
  const colorOptions = [
    { text: "Dark", value: "#000000" },
    { text: "Light", value: "#FFFFFF" },
  ];
  const timerRef = useRef(null);
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const name = urlParams.get("name");
    campaigns({ variables: { name } });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResizeEvent);
    handleResizeEvent();
    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, []);

  useEffect(() => {
    if (campaign.artworkOneLink) {
      getColors(campaign.artworkOneLink);
    }
    let array = [];
    if (campaign.artworkOneLink) {
      array.push({
        text: "Artwork #1: Event Name",
        value: "eventNameArtworkOneFontSize",
      });
      array.push({
        text: "Artwork #1: Event Date",
        value: "eventDateArtworkOneFontSize",
      });
      array.push({
        text: "Artwork #1: Event Theater",
        value: "eventTheaterArtworkOneFontSize",
      });
      array.push({
        text: "Artwork #1: Event Location",
        value: "eventLocationArtworkOneFontSize",
      });
      array.push({
        text: "Artwork #1: Event RSVP",
        value: "eventContactArtworkOneFontSize",
      });
    }
    if (campaign.artworkTwoLink) {
      array.push({
        text: "Artwork #2: Event Name",
        value: "eventNameArtworkTwoFontSize",
      });
      array.push({
        text: "Artwork #2: Event Date",
        value: "eventDateArtworkTwoFontSize",
      });
      array.push({
        text: "Artwork #2: Event Theater",
        value: "eventTheaterArtworkTwoFontSize",
      });
      array.push({
        text: "Artwork #2: Event Location",
        value: "eventLocationArtworkTwoFontSize",
      });
      array.push({
        text: "Artwork #2: Event RSVP",
        value: "eventContactArtworkTwoFontSize",
      });
    }
    if (campaign.artworkThreeLink) {
      array.push({
        text: "Artwork #3: Event Name",
        value: "eventNameArtworkThreeFontSize",
      });
      array.push({
        text: "Artwork #3: Event Date",
        value: "eventDateArtworkThreeFontSize",
      });
      array.push({
        text: "Artwork #3: Event Theater",
        value: "eventTheaterArtworkThreeFontSize",
      });
      array.push({
        text: "Artwork #3: Event Location",
        value: "eventLocationArtworkThreeFontSize",
      });
      array.push({
        text: "Artwork #3: Event RSVP",
        value: "eventContactArtworkThreeFontSize",
      });
    }
    if (campaign.artworkFourLink) {
      array.push({
        text: "Artwork #4: Event Name",
        value: "eventNameArtworkFourFontSize",
      });
      array.push({
        text: "Artwork #4: Event Date",
        value: "eventDateArtworkFourFontSize",
      });
      array.push({
        text: "Artwork #4: Event Theater",
        value: "eventTheaterArtworkFourFontSize",
      });
      array.push({
        text: "Artwork #4: Event Location",
        value: "eventLocationArtworkFourFontSize",
      });
      array.push({
        text: "Artwork #4: Event RSVP",
        value: "eventContactArtworkFourFontSize",
      });
    }
    setArtworkOptions(array);
  }, [isCampaignReady]);

  useEffect(() => {
    console.log(campaign);
  }, [campaign]);

  // useEffect(() => {
  //   const img = new Image();
  //   img.src = campaign.backgroundImageLink;
  //   img.onload = () => handleImageDimensions(img.width, img.height);
  //   console.log(img);
  // }, [campaign]);

  useEffect(() => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      if (!searchDisabled) handleTheaterSearch();
    }, 500);
  }, [formFields.eventTheater]);

  // const handleImageDimensions = (w, h) => {
  //   console.log(w, h);
  //   console.log(w / h);
  //   setAspect(w / h);
  // };

  const handleResizeEvent = () => {
    if (window.innerWidth < 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const [campaigns] = useLazyQuery(CAMPAIGNS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleCampaigns_Result(result),
    onError: (error) => handleCampaigns_Error(error),
  });

  const handleCampaigns_Result = (result) => {
    console.log(result);
    if (result.campaigns) {
      setCampaign(result.campaigns[0]);
      setReady(true);
    }
  };

  const handleCampaigns_Error = (error) => {
    console.log(error);
  };

  const handleChangeFormField = (e, data) => {
    setSearchDisabled(false);
    let key = data.id;
    let value = e.target.value;
    if (key === "eventName") {
      if (value.length > 69) return;
    }
    if (key === "eventLocation") {
      if (value.length > 69) return;
    }
    if (key === "eventFont") {
      value = data.value;
    }
    const obj = { ...formFields };
    Object.assign(obj, { [key]: value });
    setFormFields(obj);
  };

  const handleChangeBackgroundColor = (color) => {
    const obj = { ...campaign };
    Object.assign(obj, { backgroundColor: color.hex });
    if (color.hsv.s < 0.5) {
      Object.assign(obj, { textColor: "#000000" });
    } else {
      Object.assign(obj, { textColor: "#FFFFFF" });
    }
    setCampaign(obj);
  };

  const handleChangeDateTime = (date) => {
    let obj = { ...formFields };
    Object.assign(obj, { eventDate: date });
    setFormFields(obj);
  };

  const handleChangeRsvpDate = (date) => {
    let obj = { ...formFields };
    Object.assign(obj, { eventRsvp: moment(date).format("LL") });
    setFormFields(obj);
  };

  const handleTheaterSearch = () => {
    if (formFields.eventTheater.length > 2) {
      theaters({
        variables: {
          searchTerm: formFields.eventTheater,
          circuitId: campaign.circuitId,
        },
      });
      setSearching(true);
    } else {
      setResults([]);
      setSearching(false);
    }
  };

  const [theaters] = useLazyQuery(THEATERS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleTheaters_Result(result),
    onError: (error) => handleTheaters_Error(error),
  });

  const handleTheaters_Result = (result) => {
    if (result.theaters.length) {
      let newArray = result.theaters.map((item) =>
        Object.assign({}, item, {
          title: item.name,
          description: item.location,
        })
      );
      setResults(newArray);
      setSearching(false);
    } else {
      setResults([]);
      setSearching(false);
    }
  };

  const handleTheaters_Error = (error) => {
    console.log(error);
    setSearching(false);
  };

  const handleResultSelect = (item) => {
    setSearchDisabled(true);
    let obj = { ...formFields };
    Object.assign(obj, {
      eventTheater: item.title,
      eventLocation: item.location,
    });
    setFormFields(obj);
    setResults([]);
  };

  const SearchResults = () => {
    return searchResults.map((item, index) => {
      return (
        <Dropdown.Item key={index} onClick={() => handleResultSelect(item)}>
          <Header>
            {item.name}
            <Header.Subheader>{item.location}</Header.Subheader>
          </Header>
        </Dropdown.Item>
      );
    });
  };

  const [createDownload] = useMutation(CREATE_DOWNLOAD, {
    onCompleted: (result) => handleCreateDownload_Result(result),
    onError: (error) => handleCreateDownload_Error(error),
  });

  const handleCreateDownload_Result = (result) => {
    console.log(result);
  };

  const handleCreateDownload_Error = (error) => {
    console.log(error);
  };

  const handleDownload = (id, width, height) => {
    setDownloading(true);
    const element = document.getElementById(id);
    if (element) {
      element.style.transform = `scale(1.0)`;
      html2canvas(element, {
        width,
        height,
        scale: 2,
        logging: true,
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        // document.body.appendChild(canvas);
        // canvas.toBlob((blob) => {
        //   saveAs(blob, "somethign", element);
        // });
        const a = document.createElement("a");
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        a.href = canvas
          .toDataURL("image/jpeg")
          .replace("image/jpeg", "image/octet-stream");
        a.download = `${campaign.name}-${width}x${height}.jpg`;
        a.click();
        // element.classList.add("hidden");
        createDownload({
          variables: {
            campaignId: campaign.campaignId,
            type: `${width}x${height}`,
          },
        });
        element.style.transform = `scale(${scale})`;
        setTimeout(() => {
          setDownloading(false);
        }, 2000);
      });
    }
  };

  const getColors = (img) => {
    extractColors(img, { crossOrigin: "anonymous" })
      .then((colors) => {
        let obj = { ...campaign };
        Object.assign(obj, {
          backgroundColor: colors[0].hex,
          buttonColor: colors[1].hex,
        });

        if (colors[0].lightness < 0.5) {
          Object.assign(obj, { textColor: "#000000" });
        } else {
          Object.assign(obj, { textColor: "#FFFFFF" });
        }
        setCampaign(obj);
        let items = [];
        for (const c of colors) {
          if (items.indexOf(c.hex) === -1) {
            items.push(c.hex);
          }
        }

        setColorPalette(items);
      })
      .catch(console.error);
  };

  const handleDecreaseFont = (key) => {
    const currentSize = formFields[key];
    let obj = { ...formFields };
    Object.assign(obj, { [key]: currentSize - 1 });
    setFormFields(obj);
  };

  const handleIncreaseFont = (key) => {
    const currentSize = formFields[key];
    let obj = { ...formFields };
    Object.assign(obj, { [key]: currentSize + 1 });
    setFormFields(obj);
  };

  const handleChangeTextColor = (e, data) => {
    let obj = { ...campaign };
    Object.assign(obj, { textColor: data.value });
    setCampaign(obj);
  };

  const handleChangeFontWeight = (key) => {
    let currentWeight = formFields[key];
    let newWeight = 0;
    if (currentWeight === 400) newWeight = 800;
    if (currentWeight === 800) newWeight = 400;
    let obj = { ...formFields };
    Object.assign(obj, { [key]: newWeight });
    setFormFields(obj);
  };

  const handleChangeLogo = async (file) => {
    const fileExt = file.type.split("/")[1];
    let image;
    image = await resizeLogo(file, fileExt);
    console.log(image);
    setLogoFile(image);
  };

  const resizeLogo = (file, ext) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        250,
        250,
        ext,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        250,
        250
      );
    });

  return (
    <div
      style={{
        backgroundColor: "#000000",
        minHeight: "100%",
        overflow: "auto",
        width: "100%",
        position: "absolute",
      }}
    >
      <Dimmer active={downloading} page>
        <Header as="h2" icon inverted>
          <Icon name="circle notch" loading />
          Downloading
          <Header.Subheader>Delivering the goods...</Header.Subheader>
        </Header>
      </Dimmer>
      <Dimmer active={initializing} page>
        <Header as="h2" icon inverted>
          <Icon name="circle notch" loading />
          Preparing Invitation Builder
          <Header.Subheader>Loading the good stuff...</Header.Subheader>
        </Header>
      </Dimmer>
      <Downloader
        campaign={campaign}
        formFields={formFields}
        scale={scale}
        scaleFactor={scaleFactor}
        setScale={setScale}
        setScaleFactor={setScaleFactor}
        downloading={downloading}
        setInit={setInit}
        initializing={initializing}
        handleIncreaseFont={handleIncreaseFont}
        handleDecreaseFont={handleDecreaseFont}
        handleChangeFontWeight={handleChangeFontWeight}
        logoFile={logoFile}
        isLogo={isLogo}
        logoPosition={logoPosition}
      />

      {!initializing && (
        <div
          className="animate__animated animate__slideInDown"
          style={{
            position: "fixed",
            right: 400,
            top: 0,
            backgroundColor: "#000000",
            height: "100%",
            width: isMobile ? "100%" : "250px",
            padding: "20px",
            overflowY: "auto",
            overflowX: "hidden",
            //  opacity: initializing ? 0 : 1,
            //  transition: "ease-in 0.3s opacity",
          }}
        >
          <Header
            // className="extra-bold"
            as="h2"
            style={{
              textAlign: "center",
              color: "#FFFFFF",
              margin: "20px",
              fontFamily: "Oswald",
            }}
            content="Download"
          />
          {campaign.artworkOneLink && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div className="circle-bullet">#1</div>
                  <Header
                    inverted
                    as="h2"
                    style={{
                      textAlign: "celeftnter",
                      color: "#FFFFFF",
                      fontFamily: "Oswald",
                      margin: "0px 0px 0px 10px",
                    }}
                    content="Portrait"
                    subheader="1080 x 1920"
                  />
                </div>
                <Button
                  size="large"
                  className="custom-button"
                  content="Download"
                  icon="download"
                  primary
                  onClick={() => handleDownload("artworkOne", 1080, 1920)}
                />
              </div>
              <Divider />
            </div>
          )}
          {campaign.artworkTwoLink && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div className="circle-bullet">#2</div>
                  <Header
                    inverted
                    as="h2"
                    style={{
                      textAlign: "left",
                      color: "#FFFFFF",
                      fontFamily: "Oswald",
                      margin: "0px 0px 0px 10px",
                    }}
                    content="Square"
                    subheader="1080 x 1080"
                  />
                </div>
                <Button
                  size="large"
                  content="Download"
                  icon="download"
                  primary
                  onClick={() => handleDownload("artworkTwo", 1080, 1080)}
                />
              </div>
              <Divider />
            </div>
          )}
          {campaign.artworkThreeLink && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div className="circle-bullet">#3</div>
                  <Header
                    inverted
                    as="h2"
                    style={{
                      textAlign: "left",
                      color: "#FFFFFF",
                      fontFamily: "Oswald",
                      margin: "0px 0px 0px 10px",
                    }}
                    content="Landscape"
                    subheader="1920 x 1080"
                  />
                </div>

                <Button
                  size="large"
                  content="Download"
                  icon="download"
                  primary
                  onClick={() => handleDownload("artworkThree", 1920, 1080)}
                />
              </div>
              <Divider />
            </div>
          )}
          {campaign.artworkFourLink && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div className="circle-bullet">#4</div>
                  <Header
                    inverted
                    as="h2"
                    style={{
                      textAlign: "left",
                      color: "#FFFFFF",
                      fontFamily: "Oswald",
                      margin: "0px 0px 0px 10px",
                    }}
                    content='8.5" x 11"'
                    subheader="2550 x 3300"
                  />
                </div>

                <Button
                  size="large"
                  content="Download"
                  icon="download"
                  primary
                  onClick={() => handleDownload("artworkFour", 2550, 3300)}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {!initializing && (
        <div
          className="animate__animated animate__slideInRight"
          id="customizeForm"
          style={{
            position: "fixed",
            right: 0,
            top: 0,
            backgroundColor: "#000000",
            height: "100vh",
            width: isMobile ? "100%" : "400px",
            padding: "20px",
            // opacity: initializing ? 0 : 1,
            // transition: "ease-in 0.3s opacity",
          }}
        >
          <Header
            // className="extra-bold"
            as="h2"
            style={{
              textAlign: "center",
              color: "#FFFFFF",
              margin: "20px",
              fontFamily: "Oswald",
            }}
            content="Customize Your Invitation"
          />
          <Form
            size="large"
            style={{
              height: "calc(100% - 100px)",
              overflowY: "auto",
              padding: "20px",
              border: "1px solid rgba(215, 215, 215, 0.15)",
              borderRadius: "10px",
              backgroundColor: "#F1F1F1",
            }}
          >
            <Form.Field>
              <label>
                <span
                  style={{
                    fontWeight: 800,
                    fontSize: 18,
                  }}
                >
                  Background Color
                </span>
              </label>
              <CirclePicker
                width="100%"
                color={campaign.backgroundColor || ""}
                colors={colorPalette}
                onChangeComplete={handleChangeBackgroundColor}
              />
            </Form.Field>
            <Form.Field>
              <label>
                <span
                  style={{
                    fontWeight: 800,
                    fontSize: 18,
                  }}
                >
                  Text Color
                </span>
              </label>
              <Dropdown
                fluid
                selection
                value={campaign.textColor}
                onChange={handleChangeTextColor}
                options={colorOptions}
              />
            </Form.Field>
            <Form.Field>
              <label>
                <span
                  style={{
                    fontWeight: 800,
                    fontSize: 18,
                  }}
                >
                  Text Style
                </span>
              </label>
              <Dropdown
                style={{ fontFamily: formFields.eventFont }}
                id="eventFont"
                selection
                options={fontOptions}
                value={formFields.eventFont || ""}
                onChange={handleChangeFormField}
              />
            </Form.Field>

            {/* <Form.Field>
              <label>
                <span style={{ fontWeight: 800, fontSize: 18 }}>
                  Text Size{" "}
                  <Popup
                    trigger={
                      <Icon
                        name="info circle"
                        color="grey"
                        style={{ fontSize: "0.8em" }}
                      />
                    }
                    content="Each text element can be resized indepedently. Select the element you wish to change from the dropdown, and change the size with the arrows."
                  />
                </span>
              </label>
              <Dropdown
                placeholder="Select Text to Change Size..."
                selection
                options={artworkOptions}
                onChange={(e, data) => setActiveFontEdit(data.value)}
              />
              <FontChanger
                fontKey={activeFontEdit}
                handleIncreaseFont={handleIncreaseFont}
                handleDecreaseFont={handleDecreaseFont}
                value={formFields[activeFontEdit]}
              />
            </Form.Field> */}

            <Form.Input
              id="eventName"
              label={
                <span
                  style={{
                    fontWeight: 800,
                    fontSize: 18,
                  }}
                >
                  Event Name
                </span>
              }
              placeholder="My Event Name"
              value={formFields.eventName || ""}
              onChange={handleChangeFormField}
            />
            {/* <Form.Group>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #1 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventNameArtworkOneFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventNameArtworkOneFontSize}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #2 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventNameArtworkTwoFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventNameArtworkTwoFontSize}
                />
              </Form.Field>
            </Form.Group> */}
            {/* <Form.Group>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #3 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventNameArtworkThreeFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventNameArtworkThreeFontSize}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #4 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventNameArtworkFourFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventNameArtworkFourFontSize}
                />
              </Form.Field>
            </Form.Group> */}

            <Form.Field>
              <label>
                <span
                  style={{
                    fontWeight: 800,
                    fontSize: 18,
                  }}
                >
                  Event Date
                </span>
              </label>
              <DatePicker
                showTimeSelect
                timeIntervals={15}
                selected={formFields.eventDate}
                placeholderText="Select a Date / Time"
                dateFormat="MMMM d, yyyy h:mm aa"
                onChange={handleChangeDateTime}
              />
            </Form.Field>

            {/* <Form.Group>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #1 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventDateArtworkOneFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventDateArtworkOneFontSize}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #2 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventDateArtworkTwoFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventDateArtworkTwoFontSize}
                />
              </Form.Field>
            </Form.Group> */}
            {/* <Form.Group>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #3 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventDateArtworkThreeFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventDateArtworkThreeFontSize}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #4 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventDateArtworkFourFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventDateArtworkFourFontSize}
                />
              </Form.Field>
            </Form.Group> */}

            <Form.Field>
              <label>
                <span
                  style={{
                    fontWeight: 800,
                    fontSize: 18,
                  }}
                >
                  Event Theater
                </span>
              </label>
              <Dropdown
                icon={
                  <Icon
                    loading={isSearching}
                    name="search"
                    style={{ margin: "0px", padding: "0px" }}
                  />
                }
                selection
                search
                scrolling
                id="eventTheater"
                placeholder="Search by Name or Zip Code..."
                searchQuery={formFields.eventTheater || ""}
                onSearchChange={handleChangeFormField}
                open={searchResults && searchResults.length > 0}
                action={
                  <Button content="Search" onClick={handleTheaterSearch} />
                }
              >
                <Dropdown.Menu>
                  <SearchResults />
                </Dropdown.Menu>
              </Dropdown>
            </Form.Field>

            {/* <Form.Group>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #1 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventTheaterArtworkOneFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventTheaterArtworkOneFontSize}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #2 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventTheaterArtworkTwoFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventTheaterArtworkTwoFontSize}
                />
              </Form.Field>
            </Form.Group> */}
            {/* <Form.Group>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #3 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventTheaterArtworkThreeFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventTheaterArtworkThreeFontSize}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #4 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventTheaterArtworkFourFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventTheaterArtworkFourFontSize}
                />
              </Form.Field>
            </Form.Group> */}

            <Form.Input
              id="eventLocation"
              label={
                <span
                  style={{
                    fontWeight: 800,
                    fontSize: 18,
                  }}
                >
                  Theater Location
                </span>
              }
              placeholder="City, State, Zip Code"
              value={formFields.eventLocation || ""}
              onChange={handleChangeFormField}
            />

            {/* <Form.Group>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #1 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventLocationArtworkOneFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventLocationArtworkOneFontSize}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #2 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventLocationArtworkTwoFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventLocationArtworkTwoFontSize}
                />
              </Form.Field>
            </Form.Group> */}
            {/* <Form.Group>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #3 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventLocationArtworkThreeFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventLocationArtworkThreeFontSize}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #4 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventLocationArtworkFourFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventLocationArtworkFourFontSize}
                />
              </Form.Field>
            </Form.Group> */}

            <Form.Field>
              <label>
                <span
                  style={{
                    fontWeight: 800,
                    fontSize: 18,
                  }}
                >
                  RSVP Date
                </span>
              </label>
              <DatePicker
                value={formFields.eventRsvp}
                placeholderText="Select a Date"
                onChange={handleChangeRsvpDate}
              />
            </Form.Field>

            <Form.Input
              id="eventContact"
              label={
                <span
                  style={{
                    fontWeight: 800,
                    fontSize: 18,
                  }}
                >
                  RSVP Contact Info
                </span>
              }
              placeholder="Email, Phone Number, Contact Name, etc."
              value={formFields.eventContact || ""}
              onChange={handleChangeFormField}
            />

            {/* <Form.Group>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #1 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventContactArtworkOneFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventContactArtworkOneFontSize}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #2 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventContactArtworkTwoFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventContactArtworkTwoFontSize}
                />
              </Form.Field>
            </Form.Group> */}
            {/* <Form.Group>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #3 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventContactArtworkThreeFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventContactArtworkThreeFontSize}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <span
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Artwork #4 Text Size
                  </span>
                </label>
                <FontChanger
                  fontKey="eventContactArtworkFourFontSize"
                  handleIncreaseFont={handleIncreaseFont}
                  handleDecreaseFont={handleDecreaseFont}
                  value={formFields.eventContactArtworkFourFontSize}
                />
              </Form.Field>
            </Form.Group> */}

            <Form.Field>
              <label>
                <span
                  style={{
                    fontWeight: 800,
                    fontSize: 18,
                  }}
                >
                  Logo
                </span>
              </label>
              <Checkbox
                label="Hide / Show Logo"
                toggle
                checked={isLogo}
                onChange={() => setLogo(!isLogo)}
              />
              <div style={{ marginTop: "10px" }}>
                <FileUploader
                  handleChange={handleChangeLogo}
                  name="file"
                  types={fileTypes}
                  hoverTitle=" "
                  children={
                    <div
                      style={{
                        cursor: "pointer",
                        borderStyle: "dashed",
                        border: "3px dashed #888888",
                        flex: 1,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "10px",
                        borderColor: "#888888",
                        flexDirection: "column",
                        padding: "20px",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Header>
                          <Icon name="upload" />
                          <Header.Content>
                            Drag and drop a file here
                            <Header.Subheader>
                              Recommended Size: 250 x 250
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                        <Button size="large" content="Browse" />
                      </div>
                      <Image
                        style={{
                          maxWidth: "100%",
                          maxHeight: "250px",
                          margin: "10px",
                        }}
                        src={
                          logoFile
                            ? URL.createObjectURL(logoFile)
                            : logoPreview
                            ? logoPreview
                            : "/images/placeholder.png"
                        }
                      />
                    </div>
                  }
                />
              </div>
            </Form.Field>
            <Form.Field>
              <label>
                <span
                  style={{
                    fontWeight: 800,
                    fontSize: 18,
                  }}
                >
                  Logo Position
                </span>
              </label>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    padding: "10px",
                    flexDirection: "column",
                  }}
                >
                  <Checkbox
                    label="Top Left"
                    radio
                    checked={logoPosition === "top left"}
                    onChange={() => setLogoPosition("top left")}
                  />
                  <br />
                  <Checkbox
                    label="Bottom Left"
                    radio
                    checked={logoPosition === "bottom left"}
                    onChange={() => setLogoPosition("bottom left")}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: "10px",
                    flexDirection: "column",
                  }}
                >
                  <Checkbox
                    label="Top Right"
                    radio
                    checked={logoPosition === "top right"}
                    onChange={() => setLogoPosition("top right")}
                  />
                  <br />
                  <Checkbox
                    label="Bottom Right"
                    radio
                    checked={logoPosition === "bottom right"}
                    onChange={() => setLogoPosition("bottom right")}
                  />
                </div>
              </div>
            </Form.Field>
          </Form>
        </div>
      )}
    </div>
  );
};
export default Builder;
