import {
  Dimmer,
  Image,
  Header,
  Table,
  Button,
  Icon,
  Divider,
  Loader,
} from "semantic-ui-react";
import "./style.css";
import { useEffect, useState } from "react";
import moment from "moment";
import OverlayTable from "./OverlayTable";
import ArtworkOneOverlay from "./ArtworkOneOverlay";
import ArtworkTwoOverlay from "./ArtworkTwoOverlay";
import ArtworkThreeOverlay from "./ArtworkThreeOverlay";
import ArtworkFourOverlay from "./ArtworkFourOverlay";
const Downloader = (props) => {
  const {
    campaign,
    formFields,
    scale,
    scaleFactor,
    setScale,
    setScaleFactor,
    setInit,
    initializing,
    handleIncreaseFont,
    handleDecreaseFont,
    handleChangeFontWeight,
    logoFile,
    isLogo,
    logoPosition,
  } = props;

  const [scrollPosition, setScrollPosition] = useState("artworkOne");
  const [isHoverArtworkOne, setHoverArtworkOne] = useState(false);
  const [isHoverArtworkTwo, setHoverArtworkTwo] = useState(false);
  const [isHoverArtworkThree, setHoverArtworkThree] = useState(false);
  const [isHoverArtworkFour, setHoverArtworkFour] = useState(false);
  const [layouts, setLayouts] = useState({
    artworkOneHeaderLayout: "column",
    artworkOneHeaderJustify: "center",
    artworkOneFooterLayout: "column",
    artworkOneFooterJustify: "center",
    artworkTwoHeaderLayout: "column",
    artworkTwoHeaderJustify: "center",
    artworkTwoFooterLayout: "column",
    artworkTwoFooterJustify: "center",
    artworkThreeHeaderLayout: "column",
    artworkThreeHeaderJustify: "center",
    artworkThreeFooterLayout: "column",
    artworkThreeFooterJustify: "center",
    artworkFourHeaderLayout: "column",
    artworkFourHeaderJustify: "center",
    artworkFourFooterLayout: "column",
    artworkFourFooterJustify: "center",
  });

  useEffect(() => {
    handleInitialSize();
  }, []);

  useEffect(() => {
    console.log(isHoverArtworkOne);
  }, [isHoverArtworkOne]);

  useEffect(() => {
    // Add event listener when component mounts
    window.addEventListener("resize", handleInitialSize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleInitialSize);
    };
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  const handleInitialSize = () => {
    let factor = 9;
    const artworkContainer = document.getElementById("artworkContainer");
    const width = artworkContainer.clientWidth;
    if (width < 1920 && width > 1800) {
      factor = 9;
    }
    if (width < 1801 && width > 1600) {
      factor = 8;
    }
    if (width < 1601 && width > 1400) {
      factor = 7;
    }
    if (width < 1401 && width > 1200) {
      factor = 6;
    }
    if (width < 1201 && width > 1000) {
      factor = 5;
    }
    if (width < 1001 && width > 800) {
      factor = 4;
    }
    if (width < 801 && width > 600) {
      factor = 3;
    }
    if (width < 601 && width > 400) {
      factor = 2;
    }
    if (width < 401) {
      factor = 1;
    }
    setScaleFactor(factor);
    setScale(`0.${factor}`);
  };

  const handleScaleDown = () => {
    if (scaleFactor === 1) return;
    const newScaleFactor = scaleFactor - 1;
    const newScale = `0.${newScaleFactor}`;
    setScale(newScale);
    setScaleFactor(newScaleFactor);
  };

  const handleScaleUp = () => {
    if (scaleFactor === 9) return;
    const newScaleFactor = scaleFactor + 1;
    const newScale = `0.${newScaleFactor}`;
    setScale(newScale);
    setScaleFactor(newScaleFactor);
  };

  const getHeight = (element) => {
    const div = document.getElementById(element);
    if (div) {
      let height = div.offsetHeight * scale;
      if (element === "artworkOne") {
        height = height + 100;
      }
      if (element === "artworkTwo") {
        height = height + 50;
      }
      if (element === "artworkThree") {
        height = height + 50;
      }
      return height;
    }
  };

  const handleScrollUp = () => {
    const artworkOne = document.getElementById("artworkOne");
    const artworkTwo = document.getElementById("artworkTwo");
    const artworkThree = document.getElementById("artworkThree");
    const artworkFour = document.getElementById("artworkFour");
    if (scrollPosition === "artworkFour" && artworkThree) {
      artworkThree.scrollIntoView({
        behavior: "smooth", // Enables smooth scrolling
        block: "start", // Aligns to the top of the container
        inline: "nearest", // Aligns horizontally (useful for horizontal scrolling)
      });
      setScrollPosition("artworkThree");
    }
    if (scrollPosition === "artworkThree" && artworkTwo) {
      artworkTwo.scrollIntoView({
        behavior: "smooth", // Enables smooth scrolling
        block: "start", // Aligns to the top of the container
        inline: "nearest", // Aligns horizontally (useful for horizontal scrolling)
      });
      setScrollPosition("artworkTwo");
    }
    if (scrollPosition === "artworkTwo" && artworkOne) {
      artworkOne.scrollIntoView({
        behavior: "smooth", // Enables smooth scrolling
        block: "start", // Aligns to the top of the container
        inline: "nearest", // Aligns horizontally (useful for horizontal scrolling)
      });
      setScrollPosition("artworkOne");
    }
    if (scrollPosition === "artworkOne" && artworkOne) {
      artworkOne.scrollIntoView({
        behavior: "smooth", // Enables smooth scrolling
        block: "start", // Aligns to the top of the container
        inline: "nearest", // Aligns horizontally (useful for horizontal scrolling)
      });
      setScrollPosition("artworkOne");
    }
  };

  const handleScrollDown = () => {
    const artworkOne = document.getElementById("artworkOne");
    const artworkTwo = document.getElementById("artworkTwo");
    const artworkThree = document.getElementById("artworkThree");
    const artworkFour = document.getElementById("artworkFour");
    if (scrollPosition === "artworkOne" && artworkTwo) {
      artworkTwo.scrollIntoView({
        behavior: "smooth", // Enables smooth scrolling
        block: "start", // Aligns to the top of the container
        inline: "nearest", // Aligns horizontally (useful for horizontal scrolling)
      });
      setScrollPosition("artworkTwo");
    }
    if (scrollPosition === "artworkTwo" && artworkThree) {
      artworkThree.scrollIntoView({
        behavior: "smooth", // Enables smooth scrolling
        block: "start", // Aligns to the top of the container
        inline: "nearest", // Aligns horizontally (useful for horizontal scrolling)
      });
      setScrollPosition("artworkThree");
    }
    if (scrollPosition === "artworkThree" && artworkFour) {
      artworkFour.scrollIntoView({
        behavior: "smooth", // Enables smooth scrolling
        block: "start", // Aligns to the top of the container
        inline: "nearest", // Aligns horizontally (useful for horizontal scrolling)
      });
      setScrollPosition("artworkFour");
    }
    if (scrollPosition === "artworkFour" && artworkFour) {
      artworkFour.scrollIntoView({
        behavior: "smooth", // Enables smooth scrolling
        block: "start", // Aligns to the top of the container
        inline: "nearest", // Aligns horizontally (useful for horizontal scrolling)
      });
      setScrollPosition("artworkFour");
    }
  };

  const handleChangeLayout = (key1, key2) => {
    //key1
    let currentLayout = layouts[key1];
    let newLayout = "";
    if (currentLayout === "column") newLayout = "row";
    if (currentLayout === "row") newLayout = "column";
    //key2
    let currentJustify = layouts[key2];
    let newJustify = "";
    if (currentJustify === "center") newJustify = "space-evenly";
    if (currentJustify === "space-evenly") newJustify = "center";
    let obj = { ...layouts };
    Object.assign(obj, { [key1]: newLayout, [key2]: newJustify });
    setLayouts(obj);
  };

  // const handleRender = () => {
  //   const array = [
  //     {
  //       id: "artworkOne",
  //       width: 1080,
  //       height: 2221,
  //       alt: "artworkOne_display",
  //     },
  //     {
  //       id: "artworkTwo",
  //       width: 1392,
  //       height: 1392,
  //       alt: "artworkTwo_display",
  //     },
  //     {
  //       id: "artworkThree",
  //       width: 1920,
  //       height: 1530,
  //       alt: "artworkThree_display",
  //     },
  //   ];
  //   for (const a of array) {
  //     const element = document.getElementById(a.id);
  //     if (element) {
  //       html2canvas(element, {
  //         width: a.width,
  //         height: a.height,
  //         scale: 2,
  //         logging: true,
  //         allowTaint: true,
  //         useCORS: true,
  //       }).then((canvas) => {
  //         //   document.getElementById(a.alt).appendChild(canvas);
  //         const img = document.createElement("img");
  //         img.style = "width:100%;max-height:500px;";
  //         img.src = canvas
  //           .toDataURL("image/jpeg")
  //           .replace("image/jpeg", "image/octet-stream");
  //         document.getElementById(a.alt).appendChild(img);
  //       });
  //       // document.getElementById(a.id).classList.add("hidden");
  //     }
  //   }
  //   setTimeout(() => {
  //     setRendering(false);
  //   }, 3000);
  // };

  return (
    <div
      id="artworkContainer"
      scrollBehavior="smooth"
      style={{
        width: "calc(100% - 650px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        backgroundColor: "#292929",
        height: "100vh",
        overflow: "auto",
        opacity: initializing ? 0 : 1,
        transition: "ease-in 1s opacity",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: 5,
          right: 655,
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          size="large"
          icon="zoom"
          onClick={handleScaleUp}
          style={{ marginBottom: "5px" }}
        />
        <Button size="large" icon="zoom out" onClick={handleScaleDown} />
      </div>
      <div
        style={{
          position: "fixed",
          top: 105,
          right: 655,
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          size="large"
          icon="arrow up"
          onClick={handleScrollUp}
          style={{ marginBottom: "5px" }}
        />
        <Button size="large" icon="arrow down" onClick={handleScrollDown} />
      </div>

      {campaign.artworkOneLink && (
        <div
          onMouseEnter={() => setHoverArtworkOne(true)}
          onMouseLeave={() => setHoverArtworkOne(false)}
          id="artworkOne"
          style={{
            transform: `scale(${scale})`,
            transformOrigin: "top",
            position: "absolute",
            top: 50,
          }}
        >
          <div
            style={{
              width: "1080px",
              height: "1920px",
              backgroundColor: campaign.backgroundColor,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: layouts.artworkOneHeaderLayout,
                alignItems: "center",
                justifyContent: layouts.artworkOneHeaderJustify,
              }}
            >
              {logoFile &&
                isLogo &&
                (logoPosition === "top left" ||
                  logoPosition === "top right") && (
                  <div
                    style={{
                      position: "absolute",
                      top: 10,
                      left: logoPosition === "top left" ? 10 : null,
                      right: logoPosition === "top right" ? 10 : null,
                      height: "150px",
                    }}
                  >
                    <Image src={URL.createObjectURL(logoFile)} height="150px" />
                  </div>
                )}
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventNameArtworkOneFontSize,
                  color: campaign.textColor,
                  fontFamily: formFields.eventFont,
                  textAlign: formFields.eventNameTextAlign,
                  fontWeight: formFields.eventNameArtworkOneFontWeight,
                }}
              >
                {formFields.eventName || ""}
              </Header>
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventDateArtworkOneFontSize,
                  color: campaign.textColor,
                  textAlign: formFields.eventDateTextAlign,
                  fontFamily: formFields.eventFont,
                  fontWeight: formFields.eventDateArtworkOneFontWeight,
                }}
              >
                {formFields.eventDate
                  ? moment(formFields.eventDate).format("LLL")
                  : ""}
              </Header>
            </div>
            <div>
              <ArtworkOneOverlay
                isHoverArtworkOne={isHoverArtworkOne}
                handleIncreaseFont={handleIncreaseFont}
                handleDecreaseFont={handleDecreaseFont}
                handleChangeFontWeight={handleChangeFontWeight}
                handleChangeLayout={handleChangeLayout}
              />
              <Image
                crossOrigin="anonymous"
                src={campaign.artworkOneLink}
                style={{ width: "880px", height: "1564px" }}
                onLoad={() => setInit(false)}
              />
            </div>

            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: layouts.artworkOneFooterLayout,
                alignItems: "center",
                justifyContent: layouts.artworkOneFooterJustify,
              }}
            >
              {logoFile &&
                isLogo &&
                (logoPosition === "bottom left" ||
                  logoPosition === "bottom right") && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 10,
                      left: logoPosition === "bottom left" ? 10 : null,
                      right: logoPosition === "bottom right" ? 10 : null,
                      height: "150px",
                    }}
                  >
                    <Image src={URL.createObjectURL(logoFile)} height="150px" />
                  </div>
                )}
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventTheaterArtworkOneFontSize,
                  color: campaign.textColor,
                  fontFamily: formFields.eventFont,
                  textAlign: formFields.eventTheaterTextAlign,
                  fontWeight: formFields.eventTheaterArtworkOneFontWeight,
                }}
              >
                {formFields.eventTheater || ""}
              </Header>
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventLocationArtworkOneFontSize,
                  color: campaign.textColor,
                  fontFamily: formFields.eventFont,
                  textAlign: formFields.eventLocationTextAlign,
                  fontWeight: formFields.eventLocationArtworkOneFontWeight,
                }}
              >
                {formFields.eventLocation}
              </Header>
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventContactArtworkOneFontSize,
                  color: campaign.textColor,
                  fontFamily: formFields.eventFont,
                  textAlign: formFields.eventContactTextAlign,
                  fontWeight: formFields.eventContactArtworkOneFontWeight,
                }}
              >
                {formFields.eventRsvp
                  ? `RSVP ${formFields.eventContact} by: ${formFields.eventRsvp}`
                  : ""}
              </Header>
            </div>
          </div>
        </div>
      )}

      {campaign.artworkTwoLink && (
        <div
          id="artworkTwo"
          onMouseEnter={() => setHoverArtworkTwo(true)}
          onMouseLeave={() => setHoverArtworkTwo(false)}
          style={{
            transform: `scale(${scale})`,
            transformOrigin: "top",
            position: "absolute",
            top: getHeight("artworkOne"),
          }}
        >
          <div
            style={{
              width: "1080px",
              backgroundColor: campaign.backgroundColor,
              height: "1080px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "150px",
                backgroundColor: campaign.backgroundColor,
                display: "flex",
                alignItems: "center",
                flexDirection: layouts.artworkTwoHeaderLayout,
                justifyContent: layouts.artworkTwoHeaderJustify,
              }}
            >
              {logoFile &&
                isLogo &&
                (logoPosition === "top left" ||
                  logoPosition === "top right") && (
                  <div
                    style={{
                      position: "absolute",
                      top: 10,
                      left: logoPosition === "top left" ? 10 : null,
                      right: logoPosition === "top right" ? 10 : null,
                      height: "120px",
                    }}
                  >
                    <Image src={URL.createObjectURL(logoFile)} height="120px" />
                  </div>
                )}
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventNameArtworkTwoFontSize,
                  color: campaign.textColor,
                  fontFamily: formFields.eventFont,
                  textAlign: formFields.eventNameTextAlign,
                  fontWeight: formFields.eventNameArtworkTwoFontWeight,
                }}
              >
                {formFields.eventName || ""}
              </Header>
            </div>
            <div>
              <ArtworkTwoOverlay
                isHoverArtworkTwo={isHoverArtworkTwo}
                handleIncreaseFont={handleIncreaseFont}
                handleDecreaseFont={handleDecreaseFont}
                handleChangeFontWeight={handleChangeFontWeight}
                handleChangeLayout={handleChangeLayout}
              />
              <Image
                crossOrigin="anonymous"
                src={campaign.artworkTwoLink}
                style={{ width: "780px", height: "780px" }}
              />
            </div>

            <div
              style={{
                width: "100%",
                height: "150px",
                display: "flex",
                flexDirection: layouts.artworkTwoFooterLayout,
                alignItems: "center",
                justifyContent: layouts.artworkTwoFooterJustify,
              }}
            >
              {logoFile &&
                isLogo &&
                (logoPosition === "bottom left" ||
                  logoPosition === "bottom right") && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 10,
                      left: logoPosition === "bottom left" ? 10 : null,
                      right: logoPosition === "bottom right" ? 10 : null,
                      height: "120px",
                    }}
                  >
                    <Image src={URL.createObjectURL(logoFile)} height="120px" />
                  </div>
                )}
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventDateArtworkTwoFontSize,
                  color: campaign.textColor,
                  fontFamily: formFields.eventFont,
                  textAlign: formFields.eventDateTextAlign,
                  fontWeight: formFields.eventDateArtworkTwoFontWeight,
                }}
              >
                {formFields.eventDate
                  ? moment(formFields.eventDate).format("LLL")
                  : ""}
              </Header>
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventTheaterArtworkTwoFontSize,
                  color: campaign.textColor,
                  textAlign: formFields.eventTheaterTextAlign,
                  fontWeight: formFields.eventTheaterArtworkTwoFontWeight,
                }}
              >
                {formFields.eventTheater}
              </Header>
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventLocationArtworkTwoFontSize,
                  color: campaign.textColor,
                  textAlign: formFields.eventLocationTextAlign,
                  fontWeight: formFields.eventLocationArtworkTwoFontWeight,
                }}
              >
                {formFields.eventLocation}
              </Header>
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventContactArtworkTwoFontSize,
                  color: campaign.textColor,
                  fontFamily: formFields.eventFont,
                  textAlign: formFields.eventContactTextAlign,
                  fontWeight: formFields.eventContactArtworkTwoFontWeight,
                }}
              >
                {formFields.eventRsvp
                  ? `RSVP ${formFields.eventContact} by: ${formFields.eventRsvp}`
                  : ""}
              </Header>
            </div>
          </div>
        </div>
      )}

      {campaign.artworkThreeLink && (
        <div
          id="artworkThree"
          onMouseEnter={() => setHoverArtworkThree(true)}
          onMouseLeave={() => setHoverArtworkThree(false)}
          style={{
            transform: `scale(${scale})`,
            transformOrigin: "top",
            position: "absolute",
            top: getHeight("artworkOne") + getHeight("artworkTwo"),
          }}
        >
          <div
            style={{
              width: "1920px",
              height: "1080px",
              backgroundColor: campaign.backgroundColor,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: layouts.artworkThreeHeaderLayout,
                alignItems: "center",
                justifyContent: layouts.artworkThreeHeaderJustify,
              }}
            >
              {logoFile &&
                isLogo &&
                (logoPosition === "top left" ||
                  logoPosition === "top right") && (
                  <div
                    style={{
                      position: "absolute",
                      top: 10,
                      left: logoPosition === "top left" ? 10 : null,
                      right: logoPosition === "top right" ? 10 : null,
                      height: "120px",
                    }}
                  >
                    <Image src={URL.createObjectURL(logoFile)} height="120px" />
                  </div>
                )}
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventNameArtworkThreeFontSize,
                  color: campaign.textColor,
                  fontFamily: formFields.eventFont,
                  textAlign: formFields.eventNameTextAlign,
                  fontWeight: formFields.eventNameArtworkThreeFontWeight,
                }}
              >
                {formFields.eventName || ""}
              </Header>
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventDateArtworkThreeFontSize,
                  color: campaign.textColor,
                  fontFamily: formFields.eventFont,
                  fontWeight: formFields.eventDateArtworkThreeFontWeight,
                }}
              >
                {formFields.eventDate
                  ? moment(formFields.eventDate).format("LLL")
                  : ""}
              </Header>
            </div>
            <div>
              <ArtworkThreeOverlay
                isHoverArtworkThree={isHoverArtworkThree}
                handleIncreaseFont={handleIncreaseFont}
                handleDecreaseFont={handleDecreaseFont}
                handleChangeFontWeight={handleChangeFontWeight}
                handleChangeLayout={handleChangeLayout}
              />
              <Image
                crossOrigin="anonymous"
                src={campaign.artworkThreeLink}
                style={{ width: "1420px", height: "799px" }}
              />
            </div>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: layouts.artworkThreeFooterLayout,
                alignItems: "center",
                justifyContent: layouts.artworkThreeFooterJustify,
              }}
            >
              {logoFile &&
                isLogo &&
                (logoPosition === "bottom left" ||
                  logoPosition === "bottom right") && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 10,
                      left: logoPosition === "bottom left" ? 10 : null,
                      right: logoPosition === "bottom right" ? 10 : null,
                      height: "120px",
                    }}
                  >
                    <Image src={URL.createObjectURL(logoFile)} height="120px" />
                  </div>
                )}
              <Header
                className="invite-text"
                style={{
                  color: campaign.textColor,
                  fontFamily: formFields.eventFont,
                  fontSize: formFields.eventTheaterArtworkThreeFontSize,
                  fontWeight: formFields.eventTheaterArtworkThreeFontWeight,
                  textAlign: "center",
                }}
              >
                {formFields.eventTheater || ""}
              </Header>
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventLocationArtworkThreeFontSize,
                  fontWeight: formFields.eventLocationArtworkThreeFontWeight,
                  color: campaign.textColor,
                }}
              >
                {formFields.eventLocation}
              </Header>
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventContactArtworkThreeFontSize,
                  color: campaign.textColor,
                  fontFamily: formFields.eventFont,
                  fontWeight: formFields.eventContactArtworkThreeFontWeight,
                }}
              >
                {formFields.eventRsvp
                  ? `RSVP ${formFields.eventContact} by: ${formFields.eventRsvp}`
                  : ""}
              </Header>
            </div>
          </div>
        </div>
      )}

      {campaign.artworkFourLink && (
        <div
          id="artworkFour"
          onMouseEnter={() => setHoverArtworkFour(true)}
          onMouseLeave={() => setHoverArtworkFour(false)}
          style={{
            transform: `scale(${scale})`,
            transformOrigin: "top",
            position: "absolute",
            top:
              getHeight("artworkOne") +
              getHeight("artworkTwo") +
              getHeight("artworkThree"),
          }}
        >
          <div
            style={{
              width: "2550px",
              height: "3300px",
              backgroundColor: campaign.backgroundColor,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "350px",
                display: "flex",
                flexDirection: layouts.artworkFourHeaderLayout,
                alignItems: "center",
                justifyContent: layouts.artworkFourHeaderJustify,
              }}
            >
              {logoFile &&
                isLogo &&
                (logoPosition === "top left" ||
                  logoPosition === "top right") && (
                  <div
                    style={{
                      position: "absolute",
                      top: 10,
                      left: logoPosition === "top left" ? 10 : null,
                      right: logoPosition === "top right" ? 10 : null,
                      height: "250px",
                    }}
                  >
                    <Image src={URL.createObjectURL(logoFile)} height="250px" />
                  </div>
                )}
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventNameArtworkFourFontSize,
                  color: campaign.textColor,
                  fontFamily: formFields.eventFont,
                  textAlign: formFields.eventNameTextAlign,
                  fontWeight: formFields.eventNameArtworkFourFontWeight,
                }}
              >
                {formFields.eventName || ""}
              </Header>
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventDateArtworkFourFontSize,
                  color: campaign.textColor,
                  textAlign: formFields.eventDateTextAlign,
                  fontWeight: formFields.eventDateArtworkFourFontWeight,
                }}
              >
                {formFields.eventDate
                  ? moment(formFields.eventDate).format("LLL")
                  : ""}
              </Header>
            </div>
            <div>
              <ArtworkFourOverlay
                isHoverArtworkFour={isHoverArtworkFour}
                handleIncreaseFont={handleIncreaseFont}
                handleDecreaseFont={handleDecreaseFont}
                handleChangeFontWeight={handleChangeFontWeight}
                handleChangeLayout={handleChangeLayout}
              />
              <Image
                crossOrigin="anonymous"
                src={campaign.artworkFourLink}
                style={{ width: "2050px", height: "2653px" }}
              />
            </div>
            <div
              style={{
                width: "100%",
                height: "300px",
                display: "flex",
                flexDirection: layouts.artworkFourFooterLayout,
                alignItems: "center",
                justifyContent: layouts.artworkFourFooterJustify,
              }}
            >
              {logoFile &&
                isLogo &&
                (logoPosition === "bottom left" ||
                  logoPosition === "bottom right") && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 10,
                      left: logoPosition === "bottom left" ? 10 : null,
                      right: logoPosition === "bottom right" ? 10 : null,
                      height: "250px",
                    }}
                  >
                    <Image src={URL.createObjectURL(logoFile)} height="250px" />
                  </div>
                )}
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventTheaterArtworkFourFontSize,
                  color: campaign.textColor,
                  fontFamily: formFields.eventFont,
                  textAlign: formFields.eventTheaterTextAlign,
                  fontWeight: formFields.eventTheaterArtworkFourFontWeight,
                }}
              >
                {formFields.eventTheater || ""}
              </Header>
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventLocationArtworkFourFontSize,
                  color: campaign.textColor,
                  textAlign: formFields.eventLocationTextAlign,
                  fontWeight: formFields.eventLocationArtworkFourFontWeight,
                }}
              >
                {formFields.eventLocation}
              </Header>
              <Header
                className="invite-text"
                style={{
                  fontSize: formFields.eventContactArtworkFourFontSize,
                  color: campaign.textColor,
                  fontFamily: formFields.eventFont,
                  textAlign: formFields.eventContactTextAlign,
                  fontWeight: formFields.eventContactArtworkFourFontWeight,
                }}
              >
                {formFields.eventRsvp
                  ? `RSVP ${formFields.eventContact} by: ${formFields.eventRsvp}`
                  : ""}
              </Header>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Downloader;
